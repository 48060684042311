import { ReactElement } from 'react';

function ViewerBackArrowBtnSVG(): ReactElement {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.6668 6.16676H3.5252L8.18332 1.50801L6.99988 0.333008L0.333008 6.99988L6.99988 13.6668L8.17488 12.4918L3.5252 7.83301H13.6668V6.16676Z"
        fill="white"
      />
    </svg>
  );
}

export default ViewerBackArrowBtnSVG;
