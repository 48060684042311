import { ReactElement } from 'react';

function OperatorNameChangeIconSVG(): ReactElement {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.7025 4.06027L7.912 1.29863L8.83123 0.378082C9.08293 0.126027 9.39208 0 9.75868 0C10.1253 0 10.4344 0.126027 10.6861 0.378082L11.6054 1.29863C11.8571 1.55068 11.9884 1.85479 11.9993 2.21096C12.0103 2.56712 11.8899 2.87123 11.6382 3.12329L10.7025 4.06027ZM9.75047 5.03014L2.79054 12H0V9.20548L6.95993 2.23562L9.75047 5.03014Z"
        fill="#B4B4B4"
      />
    </svg>
  );
}

export default OperatorNameChangeIconSVG;
