import { ReactElement } from 'react';

function UsersMenuIconSVG(): ReactElement {
  return (
    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 6C12.6569 6 14 4.65723 14 3C14 1.34277 12.6569 0 11 0C9.97351 0 9.0675 0.515625 8.52661 1.30176C9.97839 1.90137 11 3.33203 11 5C11 5.34473 10.9565 5.67773 10.8746 5.99707C10.9163 5.99902 10.958 6 11 6Z"
        fill="currentcolor"
      />
      <path
        d="M17 14H14.9291C14.5128 11.0898 12.3068 8.75781 9.46191 8.15332C9.8656 7.83789 10.207 7.44629 10.465 7H12C15.3137 7 18 9.68652 18 13C18 13.5527 17.5522 14 17 14Z"
        fill="currentcolor"
      />
      <path
        d="M10 5C10 6.65685 8.65685 8 7 8C5.34315 8 4 6.65685 4 5C4 3.34315 5.34315 2 7 2C8.65685 2 10 3.34315 10 5Z"
        fill="currentcolor"
      />
      <path
        d="M0 15C0 11.6863 2.68629 9 6 9H8C11.3137 9 14 11.6863 14 15C14 15.5523 13.5523 16 13 16H1C0.447716 16 0 15.5523 0 15Z"
        fill="currentcolor"
      />
    </svg>
  );
}

export default UsersMenuIconSVG;
