/* eslint-disable camelcase */
export const getFirsLettersFromInitials = (fullName): string => {
  const arrayFromEnteredFullName = fullName.trim().split(' ');
  const firstTwoLettersFromName = arrayFromEnteredFullName.reduce((acc, curr, index) => {
    if (index === 0 || index === arrayFromEnteredFullName.length - 1) {
      acc = `${acc}${curr.charAt(0).toUpperCase()}`;
    }
    return acc;
  }, '');
  return firstTwoLettersFromName;
};

export const EMPTY_OBJECT = {};
export const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY HH:mm:ss';
export const checkIsWspSearchByDatasourceName = (text_search): boolean =>
  !Number.isNaN(+text_search[0]);
