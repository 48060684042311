export enum allUserRolesEnum {
  ANALYST = 'analyst',
  AUDITOR = 'auditor',
  DEVELOPER = 'developer',
  READ_ONLY = 'read_only',
  CUSTOMER = 'customer',
  SUPERVISOR = 'supervisor',
  ADMIN = 'admin',
}

export const allUserRoles = [
  allUserRolesEnum.ANALYST,
  allUserRolesEnum.AUDITOR,
  allUserRolesEnum.DEVELOPER,
  allUserRolesEnum.READ_ONLY,
  allUserRolesEnum.CUSTOMER,
  allUserRolesEnum.SUPERVISOR,
  allUserRolesEnum.ADMIN,
];
