import { ReactElement } from 'react';

function DataConnectionIconSidebarMenuSVG({color = 'transparent'}): ReactElement {
  return (
    <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M12.3544 0.362961C10.8806 0.0987813 9.36108 0.300649 8.00737 0.940466C6.77126 1.5247 5.72954 2.44756 5.00098 3.5982C4.40565 3.69011 3.82814 3.8775 3.29099 4.15391C2.63626 4.49082 2.0543 4.9534 1.57834 5.51522C1.10238 6.07704 0.741748 6.72711 0.517023 7.42831C0.29233 8.12941 0.207911 8.8679 0.268585 9.60163C0.328638 10.3353 0.532581 11.0499 0.868774 11.7047C1.20503 12.3596 1.667 12.942 2.22831 13.4184C2.78962 13.8948 3.43927 14.2559 4.14016 14.4813C4.83098 14.7033 5.55826 14.7892 6.2816 14.7341H14.3945C14.1737 14.2758 14.0377 13.7691 14.0068 13.2341H6.25206C6.23163 13.2341 6.21122 13.2349 6.19087 13.2366C5.65343 13.2806 5.1126 13.2183 4.59924 13.0532C4.08588 12.8882 3.61006 12.6237 3.19894 12.2747C2.78783 11.9258 2.44947 11.4993 2.20318 11.0196C1.9569 10.5399 1.80751 10.0164 1.76356 9.47895C1.71908 8.94144 1.78085 8.39972 1.94546 7.8861C2.11006 7.37249 2.37422 6.89633 2.72285 6.48481C3.07148 6.07329 3.49775 5.73446 3.97732 5.48768C4.4569 5.2409 4.98038 5.09099 5.51789 5.04652C5.7665 5.02596 5.98861 4.88313 6.11047 4.66546C6.68954 3.63117 7.57665 2.80314 8.64834 2.29662C9.72003 1.7901 10.923 1.63029 12.0897 1.83943C13.2565 2.04857 14.329 2.61626 15.1581 3.46349C15.9778 4.30121 16.5191 5.37102 16.7088 6.52698C16.7193 6.6312 16.7516 6.73358 16.8055 6.82704C16.9314 7.04518 17.1585 7.18555 17.4099 7.20072C18.2099 7.24898 18.9579 7.61305 19.4895 8.21283C19.8547 8.6249 20.0972 9.12503 20.1975 9.65715C20.8098 10.0604 21.3046 10.6274 21.6199 11.2957C21.6846 11.0315 21.7255 10.7615 21.7417 10.4887C21.8137 9.29178 21.4074 8.11529 20.6121 7.21793C19.9483 6.46898 19.059 5.96567 18.0871 5.7776C17.785 4.5125 17.1448 3.34909 16.2302 2.4144C15.183 1.34422 13.8282 0.627141 12.3544 0.362961Z" fill="#D8D8D8"/>
      <path d="M21.0001 13C21.0001 14.6569 19.6569 16 18.0001 16C16.3432 16 15.0001 14.6569 15.0001 13C15.0001 11.3431 16.3432 10 18.0001 10C19.6569 10 21.0001 11.3431 21.0001 13Z" fill={color}/>
    </svg>
  );
}

export default DataConnectionIconSidebarMenuSVG;
