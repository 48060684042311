import { ReactElement } from 'react';

function ClockIconSVG(): ReactElement {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.3334 10L8.33337 8V5M1.33337 8C1.33337 8.91925 1.51443 9.82951 1.86622 10.6788C2.218 11.5281 2.73362 12.2997 3.38363 12.9497C4.03364 13.5998 4.80531 14.1154 5.65459 14.4672C6.50387 14.8189 7.41412 15 8.33337 15C9.25263 15 10.1629 14.8189 11.0122 14.4672C11.8614 14.1154 12.6331 13.5998 13.2831 12.9497C13.9331 12.2997 14.4487 11.5281 14.8005 10.6788C15.1523 9.82951 15.3334 8.91925 15.3334 8C15.3334 7.08075 15.1523 6.1705 14.8005 5.32122C14.4487 4.47194 13.9331 3.70026 13.2831 3.05025C12.6331 2.40024 11.8614 1.88463 11.0122 1.53284C10.1629 1.18106 9.25263 1 8.33337 1C7.41412 1 6.50387 1.18106 5.65459 1.53284C4.80531 1.88463 4.03364 2.40024 3.38363 3.05025C2.73362 3.70026 2.218 4.47194 1.86622 5.32122C1.51443 6.1705 1.33337 7.08075 1.33337 8Z"
        stroke="#949494"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ClockIconSVG;
