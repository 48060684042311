import { ReactElement } from 'react';
import { CircularProgress } from '@mui/material';
import cn from 'classnames';

import styles from './styles.module.scss';

type SrCircularProgressPropsType = {
  additionalWrapperClassName?: string;
  additionalSpinnerClassName?: string;
  text?: string;
};

export default function SrCircularProgress({
  additionalWrapperClassName = '',
  additionalSpinnerClassName = '',
  text = '',
}: SrCircularProgressPropsType): ReactElement {
  return (
    <div
      className={cn(styles.SrCircularProgress__wrapper, {
        [additionalWrapperClassName]: !!additionalWrapperClassName,
      })}
    >
      {text && <div className={styles.SrCircularProgress__text}>{text}</div>}
      <CircularProgress
        className={cn({
          [additionalSpinnerClassName]: !!additionalSpinnerClassName,
        })}
      />
    </div>
  );
}
