import { ReactElement } from 'react';

function TasksMenuIconSVG(): ReactElement {
  return (
    <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 3H12V4H6V3Z" fill="currentColor" />
      <path d="M13 5H5V6H13V5Z" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 6L3.40627 0.8906C3.7772 0.334202 4.40166 0 5.07037 0H12.9296C13.5983 0 14.2228 0.334202 14.5937 0.8906L18 6V13C18 14.1046 17.1046 15 16 15H2C0.89543 15 0 14.1046 0 13V6ZM4.23832 1.4453C4.42378 1.1671 4.73601 1 5.07037 1H12.9296C13.264 1 13.5762 1.1671 13.7617 1.4453L16.7981 6H16V7H14V8H4V7H2V6H1.20185L4.23832 1.4453Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default TasksMenuIconSVG;
