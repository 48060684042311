import { styled } from '@mui/material/styles';
import { LinearProgress } from '@mui/material';

interface CustomizedLinearProgressProps {
  custom_color: number;
}

export const CustomizedLinearProgress = styled(LinearProgress)<CustomizedLinearProgressProps>`
  &.MuiLinearProgress-root {
    background: #5c5858;
    border-radius: 3px;
    height: 7px;
  }

  & .MuiLinearProgress-bar {
    background: ${(props) =>
      props.custom_color === 100
        ? 'rgb(92, 201, 75)'
        : `rgb(255, ${255 - props.custom_color}, 0)};`}
`;

export function isSelectedSeveralRowsToAssign(obj: any): boolean {
  return Object.keys(obj).length === 0;
}

export const historyStateNamingForRunsOpenedFromWSP = 'fromWSP';
