import { createSlice } from '@reduxjs/toolkit';
import { nanoid } from 'nanoid';
import { toast } from 'react-toastify';

import { AUTHORIZATION_HEADER, axiosInstance } from 'shared/api/http-common';
import { patchUpdateCompanyIdInRunInfo, setRunMetaData } from './viewerReducer';

export interface LTLCustomersSdmsInterdace {
  custnum: number;
  name: string;
  sdms_url: string;
}

export interface libraryTrackLocationsSdmsInterface {
  custnum: number;
  custname: string;
  Division: string;
  DivisionAbv: string;
  sub_division: string;
  sub_divisionAbv: string;
  trackLocationID: number;
  trackAlias: string;
  trackAliasAbv: string;
  zone: string;
  milestart: number;
  mileend: number;
}

export interface libraryTrackLocationsSdmsParsedInterface
  extends libraryTrackLocationsSdmsInterface {
  id: string;
  mile_start: number;
  mile_end: number;
  pulse_count_start: number;
  pulse_count_end: number;
}

export interface libraryTrackLocationsApiInterface {
  customer_id: number;
  customer_name: string;
  division: string;
  division_abv: string;
  sub_division: string;
  sub_division_abv: string;
  track_location_id: number;
  track_alias: string;
  track_alias_abv: string;
  zone: string;
  mile_start: number;
  mile_end: number;
  pulse_count_start: number;
  pulse_count_end: number;
}

export interface operatorsNamesApiInterface {
  empid: string;
  givenname: string;
  surname: string;
}

interface LibraryTrackLocationsState {
  isLibraryTrackLocationsLoading: boolean;
  libraryTrackLocationsData: libraryTrackLocationsSdmsParsedInterface[];
  isLTLCustomersLoading: boolean;
  LTLCustomersData: LTLCustomersSdmsInterdace[];
  isSelectedTrackLocationsUpdating: boolean;
  selectedTrackLocations: libraryTrackLocationsApiInterface[];
  currentSelectedTrackLocations: libraryTrackLocationsApiInterface[];
  currentSelectedCustomerId: string;
  operatorsNamesList: operatorsNamesApiInterface[];
  isOperatorsNamesListLoading: boolean;
  isOperatorNameUpdating: boolean;
  isOperatorsNamesDropdownOpened: boolean;
  selectedOperator: operatorsNamesApiInterface;
}

export enum envForTracklocationsValues {
  'localhost' = 'dev',
  'viewer.wp2.elmersperry.com' = 'dev',
  'viewer.testing.elmersperry.com' = 'testing',
  'viewer.staging.elmersperry.com' = 'staging',
  'viewer.elmersperry.com' = 'v1',
}

export const INITIAL_SELECTED_TRACK_LOCATIONS = [];
export const INITIAL_SELECTED_OPERATOR = { empid: '', givenname: '', surname: '' };

const initialState: LibraryTrackLocationsState = {
  isLibraryTrackLocationsLoading: false,
  libraryTrackLocationsData: [],
  isLTLCustomersLoading: false,
  LTLCustomersData: [],
  isSelectedTrackLocationsUpdating: false,
  selectedTrackLocations: INITIAL_SELECTED_TRACK_LOCATIONS,
  currentSelectedTrackLocations: [],
  currentSelectedCustomerId: '',
  operatorsNamesList: [],
  isOperatorsNamesListLoading: false,
  isOperatorNameUpdating: false,
  isOperatorsNamesDropdownOpened: false,
  selectedOperator: INITIAL_SELECTED_OPERATOR,
};

export const libraryTrackLocationsSlice = createSlice({
  name: 'libraryTrackLocations',
  initialState,
  reducers: {
    setIsLibraryTrackLocationsLoading: (state, action) => {
      state.isLibraryTrackLocationsLoading = action.payload;
    },
    setLibraryTrackLocationsData: (state, action) => {
      state.libraryTrackLocationsData = action.payload;
    },
    setLTLCustomersData: (state, action) => {
      state.LTLCustomersData = action.payload;
    },
    setIsLTLCustomersLoading: (state, action) => {
      state.isLTLCustomersLoading = action.payload;
    },
    setIsSelectedTrackLocationsUpdating: (state, action) => {
      state.isSelectedTrackLocationsUpdating = action.payload;
    },
    setSelectedTrackLocations: (state, action) => {
      state.selectedTrackLocations = action.payload;
    },
    setCurrentSelectedTrackLocations: (state, action) => {
      state.currentSelectedTrackLocations = action.payload;
    },
    setCurrentSelectedCustomerId: (state, action) => {
      state.currentSelectedCustomerId = action.payload;
    },
    setOperatorsNamesList: (state, action) => {
      state.operatorsNamesList = action.payload;
    },
    setIsOperatorsNamesListLoading: (state, action) => {
      state.isOperatorsNamesListLoading = action.payload;
    },
    setIsOperatorNameUpdating: (state, action) => {
      state.isOperatorNameUpdating = action.payload;
    },
    setIsOperatorsNamesDropdownOpened: (state, action) => {
      state.isOperatorsNamesDropdownOpened = action.payload;
    },
    setSelectedOperator: (state, action) => {
      state.selectedOperator = action.payload;
    },
  },
});

export const {
  setIsLibraryTrackLocationsLoading,
  setLibraryTrackLocationsData,
  setLTLCustomersData,
  setIsLTLCustomersLoading,
  setIsSelectedTrackLocationsUpdating,
  setSelectedTrackLocations,
  setCurrentSelectedTrackLocations,
  setCurrentSelectedCustomerId,
  setOperatorsNamesList,
  setIsOperatorsNamesListLoading,
  setIsOperatorNameUpdating,
  setIsOperatorsNamesDropdownOpened,
  setSelectedOperator,
} = libraryTrackLocationsSlice.actions;

const fetchLibraryTrackLocationsRecursively = ({
  custnum,
  first = 1000,
  skip = 0,
  libraryTrackLocationsData = [],
}: any): Promise<any> =>
  axiosInstance
    .get(
      `https://sdmsapi.sperrydata.com/api/${
        envForTracklocationsValues[window.location.hostname]
      }/tracklocation`,
      {
        params: { custnum, first, skip },
        headers: {
          [AUTHORIZATION_HEADER]: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
    )
    .then((libraryTrackLocationsResponse) => {
      libraryTrackLocationsData.push(...libraryTrackLocationsResponse.data);

      if (libraryTrackLocationsResponse.data.length < first) {
        return libraryTrackLocationsData;
      }

      return fetchLibraryTrackLocationsRecursively({
        custnum,
        skip: skip + first,
        libraryTrackLocationsData,
      });
    })
    .catch((_err) => null);

export const fetchLibraryTrackLocations =
  ({ custnum }) =>
  async (dispatch, getState) => {
    dispatch(setIsLibraryTrackLocationsLoading(true));
    try {
      const allLibraryTackLocationsData = await fetchLibraryTrackLocationsRecursively({
        custnum,
      });

      if (allLibraryTackLocationsData) {
        const { runMetaData } = getState().viewer;

        const allLibraryTackLocationsDataWithStartAndEndPulsecounts =
          allLibraryTackLocationsData.map((libraryTrackLocation) => ({
            ...libraryTrackLocation,
            pulse_count_start: 0,
            pulse_count_end: Number.isNaN(+runMetaData.pulse_count_end)
              ? 0
              : +runMetaData.pulse_count_end,
            // adding two fields below for changing milepost
            mile_start: libraryTrackLocation.milestart,
            mile_end: libraryTrackLocation.mileend,
            id: nanoid(),
          }));
        dispatch(
          setLibraryTrackLocationsData(allLibraryTackLocationsDataWithStartAndEndPulsecounts)
        );
      } else {
        toast.error('Failed to load library track locations data');
      }
    } catch (_err) {
      toast.error('Failed to load library track locations data');
    }
    dispatch(setIsLibraryTrackLocationsLoading(false));
  };

export const fetchLTLCustomers = () => async (dispatch) => {
  dispatch(setIsLTLCustomersLoading(true));
  try {
    const LTLCustomersResponse = await axiosInstance.get(
      `https://sdmsapi.sperrydata.com/api/${
        envForTracklocationsValues[window.location.hostname]
      }/customer`,
      {
        headers: {
          [AUTHORIZATION_HEADER]: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
    );

    if (LTLCustomersResponse.data) {
      dispatch(setLTLCustomersData(LTLCustomersResponse.data));
    } else {
      toast.error('Failed to load library track locations customers data');
    }
  } catch (err) {
    toast.error('Failed to load library track locations customers data');
  }
  dispatch(setIsLTLCustomersLoading(false));
};

export const fetchOperatorsNames = () => async (dispatch, getState) => {
  const { runMetaData } = getState().viewer;

  dispatch(setIsOperatorsNamesListLoading(true));

  try {
    const operatorsNamesResponse = await axiosInstance.get(
      `https://sdmsapi.sperrydata.com/api/${
        envForTracklocationsValues[window.location.hostname]
      }/COList`,
      {
        headers: {
          [AUTHORIZATION_HEADER]: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
    );

    if (operatorsNamesResponse.data) {
      const operatorNameFromMetaTurnedToSDMSOperatorObject = operatorsNamesResponse.data.find(
        (operatorFullName) =>
          operatorFullName.empid === runMetaData?.sdms_operator?.empid ||
          `${operatorFullName.givenname.toLowerCase()} ${operatorFullName.surname.toLowerCase()}` ===
            runMetaData?.operator.toLowerCase()
      );

      dispatch(setOperatorsNamesList(operatorsNamesResponse.data));
      dispatch(
        setSelectedOperator(
          operatorNameFromMetaTurnedToSDMSOperatorObject || INITIAL_SELECTED_OPERATOR
        )
      );
    } else {
      toast.error('Failed to load operators names list');
    }
  } catch (err) {
    toast.error('Failed to load operators names list');
  }

  dispatch(setIsOperatorsNamesListLoading(false));
};

export const fetchPatchSelectedLibraryTrackLocations =
  ({ runId, runMetaData, operator, additionalActionAfterSuccess }) =>
  async (dispatch, getState) => {
    const { currentSelectedTrackLocations, currentSelectedCustomerId, selectedTrackLocations } =
      getState().libraryTrackLocations;
    const { runInfo } = getState().viewer;

    if (
      (`${operator?.givenname} ${operator?.surname}` !== runMetaData?.operator ||
        operator?.empid !== runMetaData?.sdms_operator?.empid) &&
      currentSelectedTrackLocations.length !== selectedTrackLocations.length
    ) {
      dispatch(setIsSelectedTrackLocationsUpdating(true));
      dispatch(setIsOperatorNameUpdating(true));
    } else if (
      (`${operator?.givenname} ${operator?.surname}` !== runMetaData?.operator ||
        operator?.empid !== runMetaData?.sdms_operator?.empid) &&
      currentSelectedTrackLocations.length === selectedTrackLocations.length
    ) {
      dispatch(setIsOperatorNameUpdating(true));
    } else {
      dispatch(setIsSelectedTrackLocationsUpdating(true));
    }

    try {
      const patchResponse = await axiosInstance.put(`runs/${runId}/meta-data`, {
        ...runMetaData,
        sdms_operator: operator,
        selected_track_location: currentSelectedTrackLocations,
      });

      if (patchResponse.data) {
        dispatch(
          setRunMetaData({
            ...runMetaData,
            sdms_operator: operator,
            selected_track_location: currentSelectedTrackLocations,
          })
        );

        dispatch(setSelectedOperator(operator));
        dispatch(setSelectedTrackLocations(currentSelectedTrackLocations));

        if (
          (`${operator?.givenname} ${operator?.surname}` !== runMetaData?.operator ||
            operator?.empid !== runMetaData?.sdms_operator?.empid) &&
          currentSelectedTrackLocations.length !== selectedTrackLocations.length
        ) {
          toast.success('Selected track locations and operator name have been successfully saved', {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        } else if (
          (`${operator?.givenname} ${operator?.surname}` !== runMetaData?.operator ||
            operator?.empid !== runMetaData?.sdms_operator?.empid) &&
          currentSelectedTrackLocations.length === selectedTrackLocations.length
        ) {
          toast.success('Operator name has been successfully saved', {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        } else {
          toast.success('Selected track locations have been successfully saved', {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        }

        if (currentSelectedCustomerId !== runInfo?.company_id) {
          dispatch(
            patchUpdateCompanyIdInRunInfo({ runId, company_id: `${currentSelectedCustomerId}` })
          );
        }

        if (additionalActionAfterSuccess) additionalActionAfterSuccess();
      } else if (
        (`${operator?.givenname} ${operator?.surname}` !== runMetaData?.operator ||
          operator?.empid !== runMetaData?.sdms_operator?.empid) &&
        currentSelectedTrackLocations.length !== selectedTrackLocations.length
      ) {
        toast.error('Failed to update selected library track locations and operator name');
      } else if (
        (`${operator?.givenname} ${operator?.surname}` !== runMetaData?.operator ||
          operator?.empid !== runMetaData?.sdms_operator?.empid) &&
        currentSelectedTrackLocations.length === selectedTrackLocations.length
      ) {
        toast.error('Failed to update operator name');
      } else {
        toast.error('Failed to update selected library track locations');
      }
    } catch (err) {
      if (
        (`${operator?.givenname} ${operator?.surname}` !== runMetaData?.operator ||
          operator?.empid !== runMetaData?.sdms_operator?.empid) &&
        currentSelectedTrackLocations.length !== selectedTrackLocations.length
      ) {
        toast.error('Failed to update selected library track locations and operator name');
      } else if (
        (`${operator?.givenname} ${operator?.surname}` !== runMetaData?.operator ||
          operator?.empid !== runMetaData?.sdms_operator?.empid) &&
        currentSelectedTrackLocations.length === selectedTrackLocations.length
      ) {
        toast.error('Failed to update operator name');
      } else {
        toast.error('Failed to update selected library track locations');
      }
    }

    if (
      (`${operator.givenname} ${operator.surname}` !== runMetaData.operator ||
        operator.empid !== runMetaData.sdms_operator.empid) &&
      currentSelectedTrackLocations.length !== selectedTrackLocations.length
    ) {
      dispatch(setIsSelectedTrackLocationsUpdating(false));
      dispatch(setIsOperatorNameUpdating(false));
    } else if (
      (`${operator.givenname} ${operator.surname}` !== runMetaData.operator ||
        operator.empid !== runMetaData.sdms_operator.empid) &&
      currentSelectedTrackLocations.length === selectedTrackLocations.length
    ) {
      dispatch(setIsOperatorNameUpdating(false));
    } else {
      dispatch(setIsSelectedTrackLocationsUpdating(false));
    }
  };

export const selectIsLibraryTrackLocationsLoading = (state): boolean =>
  state.libraryTrackLocations.isLibraryTrackLocationsLoading;
export const selectLibraryTrackLocationsData = (
  state
): libraryTrackLocationsSdmsParsedInterface[] =>
  state.libraryTrackLocations.libraryTrackLocationsData;
export const selectIsLTLCustomersLoading = (state): boolean =>
  state.libraryTrackLocations.isLTLCustomersLoading;
export const selectLTLCustomersData = (state): LTLCustomersSdmsInterdace[] =>
  state.libraryTrackLocations.LTLCustomersData;
export const selectSelectedTrackLocations = (state): libraryTrackLocationsApiInterface[] =>
  state.libraryTrackLocations.selectedTrackLocations;
export const selectIsSelectedTrackLocationsUpdating = (state): boolean =>
  state.libraryTrackLocations.isSelectedTrackLocationsUpdating;
export const selectCutrrentSelectedCustomerId = (state): string | number =>
  state.libraryTrackLocations.currentSelectedCustomerId;
export const selectCurrentSelectedTrackLocations = (state): libraryTrackLocationsApiInterface[] =>
  state.libraryTrackLocations.currentSelectedTrackLocations;
export const selectOperatorsNamesList = (state): operatorsNamesApiInterface[] =>
  state.libraryTrackLocations.operatorsNamesList;
export const selectIsOperatorsNamesListLoading = (state): boolean =>
  state.libraryTrackLocations.isOperatorsNamesListLoading;
export const selectIsOperatorNameUpdating = (state): boolean =>
  state.libraryTrackLocations.isOperatorNameUpdating;
export const selectIsOperatorsNamesDropdownOpened = (state): boolean =>
  state.libraryTrackLocations.isOperatorsNamesDropdownOpened;
export const selectSelectedOperator = (state): operatorsNamesApiInterface =>
  state.libraryTrackLocations.selectedOperator;

export default libraryTrackLocationsSlice.reducer;
