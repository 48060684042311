import { ReactElement } from 'react';

function ShortArrowSVG(): ReactElement {
  return (
    <svg viewBox="0 0 179 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 6L10 11.7735V0.226497L0 6ZM179 6L169 0.226497V11.7735L179 6ZM9 7H170V5H9V7Z"
        fill="white"
      />
    </svg>
  );
}

export default ShortArrowSVG;
