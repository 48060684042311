import { ReactElement, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { selectIsEditUserModalLoading, uploadAndUpdateUserAvatar } from 'app/store/editUserReducer';
import { selectUserInfo } from 'app/store/authReducer';
import SrButton from 'shared/components/SrButton';
import SrCircularProgress from 'shared/components/SrCircularProgress';
import { getFirsLettersFromInitials } from 'shared/utils';

import styles from './styles.module.scss';

export default function EditUserProfile({ closeEditUserProfileModal }): ReactElement {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const userInfo = useSelector(selectUserInfo);
  const isEditUserModalLoading = useSelector(selectIsEditUserModalLoading);

  const [newAvatar, setNewAvatar] = useState<any>(userInfo?.avatar);

  const fileInputRef = useRef<any>(null);

  const onChangeUserAvatar = (event): void => {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setNewAvatar(e.target?.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };
  return (
    <div>
      {isEditUserModalLoading && <SrCircularProgress />}
      <div className={styles.EditUserProfile__content}>
        <div className={styles.EditUserProfile__avatarUploadContainer}>
          {newAvatar ? (
            <img
              className={styles.EditUserProfile__avatarImg}
              src={newAvatar}
              alt={t('header.edit_profile_modal.avatar')}
            />
          ) : (
            <div className={styles.EditUserProfile__noAvatar}>
              {userInfo.name ? getFirsLettersFromInitials(userInfo.name) : 'A'}
            </div>
          )}
        </div>
        <div className={styles.EditUserProfile__userNameAndUploadButtonBlock}>
          <div className={styles.EditUserProfile__userName}>{userInfo.name}</div>
          <div className={styles.EditUserProfile__inputBlock}>
            <input
              className={styles.EditUserProfile__uploadInputHidden}
              type="file"
              id="user-profile-upload-image"
              name="image"
              accept="image/*"
              onChange={onChangeUserAvatar}
              ref={fileInputRef}
            />
            <label htmlFor="user-profile-upload-image">
              <SrButton
                title={t('header.edit_profile_modal.upload_file')}
                onClick={() =>
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  fileInputRef.current.click()
                }
                customClassName={styles.EditUserProfile__uploadButton}
              />
            </label>
            {newAvatar && (
              <div className={styles.EditUserProfile__avatarDelete}>
                <SrButton
                  title={t('header.edit_profile_modal.delete_file')}
                  customClassName={styles.EditUserProfile__deleteFileButton}
                  onClick={() => {
                    setNewAvatar(null);
                    fileInputRef.current.value = '';
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles.EditUserProfile__footerButton}>
        <SrButton
          title={t('header.edit_profile_modal.submit_button')}
          onClick={() => {
            dispatch(uploadAndUpdateUserAvatar(t, newAvatar, closeEditUserProfileModal));
          }}
        />
      </div>
    </div>
  );
}
