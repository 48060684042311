import { ReactElement } from 'react';

function RunRejectionIconSVG(): ReactElement {
  return (
    <svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.7518 9.83363C15.1989 9.83363 20.0854 11.0529 20.0854 13.5001V15.3336H5.41823V13.5001C5.41823 11.0529 10.3046 9.83363 12.7518 9.83363ZM12.7518 8.00006C10.7269 8.00006 9.08536 6.35866 9.08536 4.33363C9.08536 2.30808 10.7268 0.666504 12.7518 0.666504C14.7767 0.666504 16.4182 2.30791 16.4182 4.33363C16.4182 6.35849 14.7768 8.00006 12.7518 8.00006ZM3.58536 5.79113L5.5284 3.83811L6.83019 5.13989L4.87769 7.08363L6.83019 9.02667L5.5284 10.3285L3.58536 8.37596L1.64162 10.3285L0.339844 9.02667L2.29286 7.08363L0.339844 5.13989L1.64162 3.83811L3.58536 5.79113Z"
        fill="#FFE966"
      />
    </svg>
  );
}

export default RunRejectionIconSVG;
