import { createSlice } from '@reduxjs/toolkit';
import { User } from 'pages/admin/users/_ui/constants';
import { toast } from 'react-toastify';

import { axiosInstance } from 'shared/api/http-common';

type AdminUsersState = {
  usersData: Array<User>;
  isUsersDataLoading: boolean;
  usersDataError: string | null;
  isAddOrEditUserRequestLoading: boolean;
  currentUser: User | null;
};

const initialState: AdminUsersState = {
  usersData: [],
  isUsersDataLoading: false,
  usersDataError: null,
  isAddOrEditUserRequestLoading: false,
  currentUser: null,
};

export const adminUsersSlice = createSlice({
  name: 'adminUsers',
  initialState,
  reducers: {
    setUsersData: (state, action) => {
      state.usersData = action.payload;
    },
    setIsUsersDataLoading: (state, action) => {
      state.isUsersDataLoading = action.payload;
    },
    setIsAddOrEditUserRequestLoading: (state, action) => {
      state.isAddOrEditUserRequestLoading = action.payload;
    },
    setUsersDataError: (state, action) => {
      state.usersDataError = action.payload;
    },
    addNewUserToUsersData: (state, action) => {
      state.usersData = [...state.usersData, action.payload];
    },
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
    updateUserData: (state, action) => {
      const newUsersData = state.usersData.map((user) => {
        if (+user.id === +action.payload.id) {
          return action.payload;
        }
        return user;
      });

      state.usersData = newUsersData;
    },
    deleteUserFromUsersData: (state, action) => {
      const newUsersData = state.usersData.filter((user) => +user.id !== +action.payload);
      state.usersData = newUsersData;
    },
  },
});

export const {
  setUsersData,
  setIsUsersDataLoading,
  setUsersDataError,
  addNewUserToUsersData,
  setCurrentUser,
  updateUserData,
  setIsAddOrEditUserRequestLoading,
  deleteUserFromUsersData,
} = adminUsersSlice.actions;

export const getUsersDataAsync = (t) => async (dispatch) => {
  dispatch(setIsUsersDataLoading(true));
  try {
    const response = await axiosInstance.get('users');
    if (response.data) {
      dispatch(setUsersData(response.data));
    } else {
      dispatch(setUsersDataError(t('api_errors.users.get.error')));
      toast.error(t('api_errors.users.get.failed'));
    }
  } catch (err) {
    dispatch(setUsersDataError(t('api_errors.users.get.error')));
    toast.error(t('api_errors.users.get.error'));
  }
  dispatch(setIsUsersDataLoading(false));
};

export const addNewUserAsync =
  (t, newUser, additionalActionAfterSuccess: () => void | undefined) => async (dispatch) => {
    dispatch(setIsAddOrEditUserRequestLoading(true));
    try {
      // TODO need to check whether to send or will be added on BE's sid on each newly created user
      newUser.userType = 'sperry';
      const response = await axiosInstance.post('users', newUser);
      if (response.data) {
        dispatch(addNewUserToUsersData(newUser));
        toast.success(t('api_errors.users.add.success'));
        if (additionalActionAfterSuccess) additionalActionAfterSuccess();
      } else {
        dispatch(setUsersDataError(t('api_errors.users.add.error')));
        toast.error(t('api_errors.users.add.fail'));
      }
    } catch (err) {
      dispatch(setUsersDataError(t('api_errors.users.add.error')));
      toast.error(t('api_errors.users.add.error'));
    }
    dispatch(setIsAddOrEditUserRequestLoading(false));
  };

export const updateUserAsync =
  (t, newUserData, additionalActionAfterSuccess: () => void) => async (dispatch) => {
    dispatch(setIsAddOrEditUserRequestLoading(true));
    try {
      const response = await axiosInstance.patch(`users/${newUserData.id}`, {
        name: newUserData.name,
      });
      if (response.data) {
        dispatch(updateUserData(newUserData));
        toast.success(t('api_errors.users.update.success'));
        if (additionalActionAfterSuccess) additionalActionAfterSuccess();
      } else {
        dispatch(setUsersDataError(t('api_errors.users.update.error')));
        toast.error(t('api_errors.users.update.fail'));
      }
    } catch (err) {
      dispatch(setUsersDataError(t('api_errors.users.update.error')));
      toast.error(t('api_errors.users.update.error'));
    }
    dispatch(setIsAddOrEditUserRequestLoading(false));
  };

export const deleteUserAsync =
  (t, id: number, additionalActionAfterSuccess: () => void) => async (dispatch) => {
    dispatch(setIsAddOrEditUserRequestLoading(true));
    try {
      const response = await axiosInstance.delete(`users/${id}`);
      if (response.data) {
        dispatch(deleteUserFromUsersData(id));
        toast.success(t('api_errors.users.delete.success'));
        if (additionalActionAfterSuccess) additionalActionAfterSuccess();
      } else {
        dispatch(setUsersDataError(t('api_errors.users.delete.error')));
        toast.error(t('api_errors.users.delete.fail'));
      }
    } catch (err) {
      dispatch(setUsersDataError(t('api_errors.users.delete.error')));
      toast.error(t('api_errors.users.delete.error'));
    }
    dispatch(setIsAddOrEditUserRequestLoading(false));
  };

export const selectIsUsersDataLoading = (state): boolean => state.adminUsers.isUsersDataLoading;
export const selectUsersData = (state): Array<User> => state.adminUsers.usersData;
export const selectCurrentUser = (state): User | null => state.adminUsers.currentUser;
export const selectIsAddOrEditUserRequestLoading = (state): boolean =>
  state.adminUsers.isAddOrEditUserRequestLoading;

export default adminUsersSlice.reducer;
