import { ReactElement } from 'react';

function LongArrowSVG(): ReactElement {
  return (
    <svg viewBox="0 0 358 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 6L10 11.7735V0.226497L0 6ZM358 6L348 0.226497V11.7735L358 6ZM9 7H349V5H9V7Z"
        fill="white"
      />
    </svg>
  );
}

export default LongArrowSVG;
