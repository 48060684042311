import { ReactElement } from 'react';

function RunInfoIconSidebarMenuSVG(): ReactElement {
  return (
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 10H14M1 13H7M2 5H14C14.5523 5 15 4.55228 15 4V2C15 1.44772 14.5523 1 14 1H2C1.44772 1 1 1.44772 1 2V4C1 4.55228 1.44772 5 2 5Z" stroke="#D8D8D8" strokeWidth="1.5" strokeLinecap="round"/>
    </svg>
  );
}

export default RunInfoIconSidebarMenuSVG;
