import { ReactElement } from 'react';

function ViewerSidebarArrowCloseSVG(): ReactElement {
  return (
    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1L7 7L1 13" stroke="#949494" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}

export default ViewerSidebarArrowCloseSVG;
