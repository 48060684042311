import { StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Amplify from 'aws-amplify';
import { ErrorBoundary } from 'react-error-boundary';
import { CookiesProvider } from 'react-cookie';

import awsConfig from 'shared/config/awsConfig';
import App from 'app';
import reportWebVitals from 'app/reportWebVitals';
import 'shared/translation/118n.js';

// eslint-disable-next-line import/no-extraneous-dependencies
import 'app/styles/global.scss';
import styles from './app/index.module.scss';

Amplify.configure(awsConfig);

function ErrorFallback({ error, resetErrorBoundary }): any {
  return (
    <div role="alert" className={styles.App__errorBoundaries}>
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button type="button" onClick={resetErrorBoundary}>
        Try again
      </button>
    </div>
  );
}

ReactDOM.render(
  <StrictMode>
    <Suspense
      fallback={
        <div className={styles.App__suspenseLoader}>
          <CircularProgress />
        </div>
      }
    >
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => {
          window.location.reload();
        }}
      >
        <CookiesProvider>
          <App />
        </CookiesProvider>
      </ErrorBoundary>
    </Suspense>
  </StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
