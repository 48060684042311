import { ReactElement } from 'react';

function UrlIconSVG(): ReactElement {
  return (
    <svg width="26" height="25" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_8146_13058)">
        <path
          d="M8.37059 11.6673L11.7039 8.33398"
          stroke="#F9F9F9"
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.20392 5.64767L9.54693 5.25065C10.2417 4.5561 11.184 4.16595 12.1664 4.16602C13.1489 4.16609 14.0911 4.55637 14.7858 5.25102C15.4804 5.94567 15.8707 6.88777 15.8706 7.87008C15.8705 8.85239 15.4802 9.79444 14.7854 10.489L14.3898 10.8327"
          stroke="#F9F9F9"
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.8706 14.3521L10.5765 14.7478C9.87375 15.4428 8.92527 15.8327 7.93693 15.8327C6.94858 15.8327 6.00011 15.4428 5.29734 14.7478C4.95094 14.4052 4.67594 13.9973 4.48825 13.5477C4.30056 13.098 4.20392 12.6156 4.20392 12.1284C4.20392 11.6412 4.30056 11.1588 4.48825 10.7092C4.67594 10.2595 4.95094 9.85162 5.29734 9.50904L5.68548 9.16602"
          stroke="#F9F9F9"
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_8146_13058">
          <rect width="20" height="20" fill="white" transform="translate(0.223511)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default UrlIconSVG;
