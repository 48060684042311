import { createSlice } from '@reduxjs/toolkit';

export const googleMapsSlice = createSlice({
  name: 'googleMaps',
  initialState: {
    recognitionToUpdateOnGoogleMaps: null,
    recognitionToAddOnGoogleMaps: null,
  },
  reducers: {
    setRecognitionToUpdateOnGoogleMaps: (state, action) => {
      state.recognitionToUpdateOnGoogleMaps = action.payload;
    },
    setRecognitionToAddOnGoogleMaps: (state, action) => {
      state.recognitionToAddOnGoogleMaps = action.payload;
    },
  },
});

export const { setRecognitionToUpdateOnGoogleMaps, setRecognitionToAddOnGoogleMaps } =
  googleMapsSlice.actions;

export const selectRecognitionToUpdateOnGoogleMaps = (state): any =>
  state.googleMaps.recognitionToUpdateOnGoogleMaps;
export const selectRecognitionToAddOnGoogleMaps = (state): any =>
  state.googleMaps.recognitionToAddOnGoogleMaps;

export default googleMapsSlice.reducer;
