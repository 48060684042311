/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { ReactElement, useEffect, useRef } from 'react';
import cn from 'classnames';
import ReactDOM from 'react-dom';
import Draggable from 'react-draggable';

import SrModalContent from './_ui/SrModalContent';

import styles from './styles.module.scss';

const ESCAPE_CODE = 27;

function SrModal({
  children,
  title,
  headerClassName = '',
  contentClassName = '',
  wrapperClassName = '',
  actionButtonsWrapperAdditionalClassName = '',
  cancelBtnAdditionalClassName = '',
  confirmBtnAdditionalClassName = '',
  withCancelButton = false,
  withAcceptButton = false,
  withCloseButton = true,
  isDraggable = false,
  cancelBtnTitle,
  acceptBtnTitle,
  onClose,
  onCancel,
  onAccept,
}: {
  children?: ReactElement;
  title: string | ReactElement;
  headerClassName?: string;
  contentClassName?: string;
  wrapperClassName?: string;
  actionButtonsWrapperAdditionalClassName?: string;
  cancelBtnAdditionalClassName?: string;
  confirmBtnAdditionalClassName?: string;
  withCancelButton?: boolean;
  withAcceptButton?: boolean;
  withCloseButton?: boolean;
  isDraggable?: boolean;
  cancelBtnTitle?: string;
  acceptBtnTitle?: string;
  onClose: () => void;
  onCancel?: () => void;
  onAccept?: () => void;
}): any {
  const refForDraggable = useRef(null);

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  useEffect(() => {
    const closeModalByKeyCode = (e: { keyCode: number }): void => {
      if (e.keyCode === ESCAPE_CODE) {
        onClose();
      }
    };
    window.addEventListener('keydown', closeModalByKeyCode);
    return () => window.removeEventListener('keydown', closeModalByKeyCode);
  }, [onClose]);

  return ReactDOM.createPortal(
    <div
      className={cn(styles.SrModal__wrapper, wrapperClassName)}
      onClick={(e) => e.currentTarget === e.target && withCloseButton && onClose()}
    >
      <Draggable
        nodeRef={refForDraggable}
        disabled={!isDraggable}
        cancel="input, textarea, .disabledDraggable"
      >
        <div ref={refForDraggable} className={cn(styles.SrModal__contentWrapper, contentClassName)}>
          <SrModalContent
            title={title}
            headerClassName={headerClassName}
            contentClassName={contentClassName}
            withCancelButton={withCancelButton}
            withAcceptButton={withAcceptButton}
            withCloseButton={withCloseButton}
            cancelBtnTitle={cancelBtnTitle}
            acceptBtnTitle={acceptBtnTitle}
            onClose={onClose}
            onCancel={onCancel}
            onAccept={onAccept}
            actionButtonsWrapperAdditionalClassName={actionButtonsWrapperAdditionalClassName}
            cancelBtnAdditionalClassName={cancelBtnAdditionalClassName}
            confirmBtnAdditionalClassName={confirmBtnAdditionalClassName}
          >
            {children}
          </SrModalContent>
        </div>
      </Draggable>
    </div>,
    document.body
  );
}

export default SrModal;
