export const DEFAULT_INDENT_IN_RECHARTS = 5;
export const MIN_DISTANCE_FOR_RECOGNITION = 2;
export const DEFAULT_NEW_RECOGNITION_AREA = {
  x1: 0,
  x2: 0,
  y1: 0,
  y2: 0,
};
export const MIN_DEPTH_FOR_BSCAN = 0;
export const MAX_DEPTH_FOR_BSCAN = 128;
export const DISTANSE_BETWEEN_BIG_VERTICAL_LINE_AND_SMALL_VERTICAL_LINE = 96;
export const DISTANSE_BETWEEN_TWO_BIG_VERTICAL_LINES = 192;

export const SEGMENT_LENGTH = 1000;
export const AMOUNT_OF_TSCAN_RUNS_TO_DISPLAY = 3;

export interface RecognitionInterface {
  recognition_id: number | string;
  run_id: number;
  rail: string;
  rail_id: 0 | 1;
  pulse_count: number;
  pulse_count_start: number;
  pulse_count_end: number;
  depth_start: number;
  depth_end: number;
  suspect: boolean;
  customer_text_code: string;
  text_uic_code: string;
  customer_icon_code: number | '';
  priority: number;
  milepost: number;
  source: string;
  verified: boolean;
  defect: boolean;
  comment: string;
  historic: boolean;
  historic_type: string;
  verifier_comment: string;
  lat: string;
  long: string;
  elmer_text_uic_code: string;
  generated_from: string;
}

export interface CustomerCodesInterface {
  id: number;
  customer_id: number;
  text_code: string;
  text_uic_code: string;
  icon_id: number;
  description: string;
  flaw: boolean;
  analysis_stop: boolean;
  audit_stop: boolean;
  system: string;
  priority: number;
}

export const findRunMapDataByFieldUsingBinarySearch = ({
  runMapData,
  fieldName,
  fieldValue,
}): any => {
  if (!runMapData.length) {
    return {};
  }

  let start = 0;
  let end = runMapData.length - 1;
  let lastFoundRunMapElement = {};
  let lastFoundRunMapElementIndex = 0;

  while (start <= end) {
    const middle = Math.floor((start + end) / 2);
    lastFoundRunMapElement = runMapData[middle];
    lastFoundRunMapElementIndex = middle;
    if (runMapData[middle][fieldName] === fieldValue) {
      // found the pulse_count
      return runMapData[middle];
    }
    if (runMapData[middle][fieldName] < fieldValue) {
      // continue searching to the right
      start = middle + 1;
    } else {
      // search searching to the left
      end = middle - 1;
    }
  }

  // key wasn't found
  // check nearest values and return nearest one
  if (
    fieldValue < runMapData[lastFoundRunMapElementIndex][fieldName] &&
    runMapData[lastFoundRunMapElementIndex - 1] &&
    Math.abs(runMapData[lastFoundRunMapElementIndex - 1][fieldName] - fieldValue) <
      Math.abs(runMapData[lastFoundRunMapElementIndex][fieldName] - fieldValue)
  ) {
    return runMapData[lastFoundRunMapElementIndex - 1];
  }
  if (
    fieldValue > runMapData[lastFoundRunMapElementIndex][fieldName] &&
    runMapData[lastFoundRunMapElementIndex + 1] &&
    Math.abs(runMapData[lastFoundRunMapElementIndex + 1][fieldName] - fieldValue) <
      Math.abs(runMapData[lastFoundRunMapElementIndex][fieldName] - fieldValue)
  ) {
    return runMapData[lastFoundRunMapElementIndex + 1];
  }

  return lastFoundRunMapElement;
};

export const LEFT_CLICK_ON_MOUSE = 0;

interface RulerPoint {
  id: string | number;
  pulse_count: number;
  depth: number;
}
const INITIAL_RULER_RAIL_STATE: {
  isDisplayed: boolean;
  rulerPoints: RulerPoint[];
} = {
  isDisplayed: false,
  rulerPoints: [],
};

export const INITIAL_RULER_STATE = {
  top: INITIAL_RULER_RAIL_STATE,
  bottom: INITIAL_RULER_RAIL_STATE,
};

export const MAXIMUM_ZOOM_IN_TIMES_FROM_DEFAULT = 8;
export const MAXIMUM_ZOOM_OUT_TIMES_FROM_DEFAULT = 4;
export const ZOOM_IN_ZOOM_OUT_TRANSITION = 2;
export const HOT_AREA_FOR_VIEWER = 3 / 4;
export const AREA_FOR_ZOOM_UH = 1 / 3;

export enum AllStatusesWithColors {
  GOOD = '#00a900',
  FAILED = 'red',
}

export const determineStatusColorByArrayOfErrors = (
  arrayWithErrors: any[]
): AllStatusesWithColors => {
  if (arrayWithErrors.every((dataForViewer) => dataForViewer === null)) {
    return AllStatusesWithColors.GOOD;
  }

  return AllStatusesWithColors.FAILED;
};

export const determineStatusColorByError = (error): AllStatusesWithColors =>
  error ? AllStatusesWithColors.FAILED : AllStatusesWithColors.GOOD;

export enum movableSidebarOpenedTabValues {
  TRACK_LOCATION = 0,
  RECOGNITIONS_LIST = 1,
  SUMMARY_REPORT = 2,
  RUN_INFO = 3,
  RUN_UX_CHANNELS_INFO = 4,
  DATA_CONNECTION = 5,
  HELP_WINDOW = 6,
}

export enum movableSidebarOpenedTabNames {
  TRACK_LOCATION = 'Track locations',
  RECOGNITIONS_LIST = 'Recognitions list',
  SUMMARY_REPORT = 'Summary report',
  RUN_INFO = 'Run info',
  RUN_UX_CHANNELS_INFO = 'Run UX channels',
  DATA_CONNECTION = 'Data connection',
  HELP_WINDOW = 'Help window',
}

export enum generatedFromRecognitionTypes {
  INDUCTION = 'induction',
  ULTRASOUND = 'ultrasound',
  ENHANCED_DETECTION = 'eddy_current_generic',
}

export const PULSE_COUNT_OR_PIXEL_TO_INCH_RATE = 1 / 16;
export const PULSE_COUNT_OR_PIXEL_TO_METRE_RATE = 1 / 630;
export const INCHES_VALUE_ROUND_TO_ONE_TENTH_PART = 10;
export const METRES_VALUE_ROUND_TO_ONE_THOUSAND_PART = 1000;

export enum recognitionWindowTitles {
  VIEW_RECOGNITION = 'View Recognition',
  EDIT_RECOGNITION = 'Edit Recognition',
  MARK_SUSPECT = 'Mark Suspect',
}

// Look at INZV-893. There is a screenshot from FrontDB with angles.
export const ultrasoundChannelToAngleMap = {
  '1': 70,
  '2': 70,
  '4': 70,
  '8': 135,
  '16': 45,
  '32': 0,
  '64': 0,
  '128': 160,
  '256': 20,
  '516': 110,
  '576': 0,
  '1024': 110,
  '2048': 110,
  '4096': 110,
  '4608': 70,
  '8192': 180,
  '16384': 180,
};
