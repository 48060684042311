import { AwsRum } from 'aws-rum-web';
import { PartialConfig } from 'aws-rum-web/dist/cjs/orchestration/Orchestration';

import {
  REACT_APP_RUM_APPLICATION_ID,
  REACT_APP_RUM_APPLICATION_REGION,
  REACT_APP_RUM_APPLICATION_VERSION,
  REACT_APP_RUM_ENDPOINT,
  REACT_APP_RUM_GUEST_ROLE_ARN,
  REACT_APP_RUM_IDENTITY_POOL_ID,
} from 'shared/envs';

const config: PartialConfig = {
  sessionSampleRate: 1,
  identityPoolId: REACT_APP_RUM_IDENTITY_POOL_ID,
  endpoint: REACT_APP_RUM_ENDPOINT,
  telemetries: ['http', 'errors', 'performance'],
  allowCookies: true,
  enableXRay: true,
};

if (REACT_APP_RUM_GUEST_ROLE_ARN) {
  config.guestRoleArn = REACT_APP_RUM_GUEST_ROLE_ARN;
}

const APPLICATION_ID = REACT_APP_RUM_APPLICATION_ID || '';
const APPLICATION_VERSION = REACT_APP_RUM_APPLICATION_VERSION || '';
const APPLICATION_REGION = REACT_APP_RUM_APPLICATION_REGION || '';

const withAwsRum = (App) =>
  function AppwithAwsRum() {
    try {
      const awsRum = new AwsRum(APPLICATION_ID, APPLICATION_VERSION, APPLICATION_REGION, config);
      console.log('awsRum: ', awsRum);
    } catch (error) {
      console.log('awsRum failed, error: ', error);

      // Ignore errors thrown during CloudWatch RUM web client initialization
    }
    return <App />;
  };

export default withAwsRum;
