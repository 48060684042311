import { ReactElement, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { historyStateNamingForRunsOpenedFromWSP } from 'pages/runs/_ui/runs-table/constants';
import { selectCurrentUserRole, selectUserUniqueId } from 'app/store/authReducer';
import {
  selectCurrentXAxisPulsecounts,
  selectIsUnassignButtonLoading,
  selectMetaInfoAboutRunSegments,
  selectRunInfo,
  selectRunMetaData,
  updateCurrentRunAssigneeAndOrProgress,
} from 'app/store/viewerReducer';
import AllRoutes from 'shared/config/routes';
import SrButton from 'shared/components/SrButton';
import SrModal from 'shared/components/SrModal';
import { UrlIconSVG } from 'shared/svgs';
import SrTooltip from 'shared/components/SrTooltip';
import SrCircularProgress from 'shared/components/SrCircularProgress';
import ConfirmUnassignActionModal from './_ui/ConfirmUnassignActionModal';

import styles from './styles.module.scss';

const MAX_RUN_PROGRESS_BY_PULSECOUNT = 100;

function ViewerHeaderBts({ setCompleteRunProgressByPulsecount }): ReactElement {
  const [isConfirmUnassignActionModalOpened, setIsConfirmUnassignActionModalOpened] =
    useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { state }: any = useLocation();

  const { runId } = useParams();
  const { t } = useTranslation();

  const runMetaData = useSelector(selectRunMetaData);
  const runInfo = useSelector(selectRunInfo);
  const currentUserRole = useSelector(selectCurrentUserRole);
  const userUniqueId = useSelector(selectUserUniqueId);
  const isUnassinButtonLoading = useSelector(selectIsUnassignButtonLoading);
  const currentXAxisPulsecounts = useSelector(selectCurrentXAxisPulsecounts);
  const metaInfoAboutRunSegments = useSelector(selectMetaInfoAboutRunSegments);

  const copyUrl = (): void => {
    const urlT1kName = `${window.location.origin}/run/${runMetaData?.recording_name}/viewer${window.location.search}`;
    navigator.clipboard.writeText(urlT1kName).then(() => {
      toast.success('Link copied to clipboard', {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    });
  };

  useEffect(() => {
    // End pulsecount and/or stop pulsecount inside meta can not normally be 0
    if (currentXAxisPulsecounts.pulse_count_end && metaInfoAboutRunSegments.run_pulse_count_stop) {
      const completeRunProgressByPulsecountComputed =
        ((currentXAxisPulsecounts.pulse_count_end -
          metaInfoAboutRunSegments.run_pulse_count_start) /
          (metaInfoAboutRunSegments.run_pulse_count_stop -
            metaInfoAboutRunSegments.run_pulse_count_start)) *
        100;

      if (completeRunProgressByPulsecountComputed <= MAX_RUN_PROGRESS_BY_PULSECOUNT) {
        setCompleteRunProgressByPulsecount(completeRunProgressByPulsecountComputed);
      } else {
        setCompleteRunProgressByPulsecount(MAX_RUN_PROGRESS_BY_PULSECOUNT);
      }
    }
  }, [
    currentXAxisPulsecounts.pulse_count_start,
    currentXAxisPulsecounts.pulse_count_end,
    metaInfoAboutRunSegments.run_pulse_count_start,
    metaInfoAboutRunSegments.run_pulse_count_stop,
  ]);

  return (
    <div className={styles.ViewerHeaderBts__wrapper}>
      <div className={styles.ViewerHeaderBts__btnWrapper}>
        <SrTooltip
          title="Copy link"
          onClick={copyUrl}
          classNameForPopperProps={styles.ViewerHeaderBts__tooltipForCopyURL}
        >
          <SrButton
            title={<UrlIconSVG />}
            customClassName={styles.ViewerHeaderBts__btnIconWrapper}
            onClick={copyUrl}
          />
        </SrTooltip>
      </div>
      <div className={styles.ViewerHeaderBts__btnUnassignBlock}>
        <div className={styles.ViewerHeaderBts__btnUnassignWrapper}>
          {isUnassinButtonLoading && <SrCircularProgress />}
          <SrButton
            disabled={
              runInfo?.[currentUserRole]?.user_id !== userUniqueId ||
              runInfo?.[currentUserRole]?.progress === 100
            }
            customClassName={styles.ViewerHeaderBts__unassignBtn}
            onClick={() => {
              setIsConfirmUnassignActionModalOpened(true);
            }}
            title={t('header.viewer_header.unassign_btn')}
          />
        </div>
      </div>
      {isConfirmUnassignActionModalOpened && (
        <SrModal
          title="Unassign"
          onClose={() => {
            setIsConfirmUnassignActionModalOpened(false);
          }}
          withCancelButton
          withAcceptButton
          onCancel={() => setIsConfirmUnassignActionModalOpened(false)}
          onAccept={() => {
            dispatch(
              updateCurrentRunAssigneeAndOrProgress({
                runId,
                currentUserRole,
                personAssignedId: null,
                progressValue: 0,
                additionalActionAfterSuccess: () => {
                  if (state && state[historyStateNamingForRunsOpenedFromWSP]) {
                    navigate(-1);
                  } else {
                    navigate(AllRoutes.RUNS);
                  }
                },
              })
            );
            setIsConfirmUnassignActionModalOpened(false);
          }}
          cancelBtnTitle="Cancel"
          acceptBtnTitle="Unassign"
        >
          <ConfirmUnassignActionModal />
        </SrModal>
      )}
    </div>
  );
}

export default ViewerHeaderBts;
