import { ReactElement } from 'react';

function LockIconSVG(): ReactElement {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M 23.4375 13.125 L 21.5625 13.125 L 21.5625 6.5625 C 21.5625 2.945312 18.617188 0 15 0 C 11.382812 0 8.4375 2.945312 8.4375 6.5625 L 8.4375 13.125 L 6.5625 13.125 C 5.527344 13.125 4.6875 13.964844 4.6875 15 L 4.6875 28.125 C 4.6875 29.160156 5.527344 30 6.5625 30 L 23.4375 30 C 24.472656 30 25.3125 29.160156 25.3125 28.125 L 25.3125 15 C 25.3125 13.964844 24.472656 13.125 23.4375 13.125 Z M 15.9375 23.265625 L 15.9375 25.3125 C 15.9375 25.832031 15.519531 26.25 15 26.25 C 14.480469 26.25 14.0625 25.832031 14.0625 25.3125 L 14.0625 23.265625 C 12.972656 22.878906 12.1875 21.847656 12.1875 20.625 C 12.1875 19.070312 13.445312 17.8125 15 17.8125 C 16.554688 17.8125 17.8125 19.070312 17.8125 20.625 C 17.8125 21.847656 17.027344 22.878906 15.9375 23.265625 Z M 12.1875 13.125 L 12.1875 6.5625 C 12.1875 5.011719 13.449219 3.75 15 3.75 C 16.550781 3.75 17.8125 5.011719 17.8125 6.5625 L 17.8125 13.125 Z M 12.1875 13.125"
        fill="#ffd200"
      />
    </svg>
  );
}

export default LockIconSVG;
