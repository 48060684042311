import { ReactElement } from 'react';

function SummaryReportIconSidebarMenuSVG(): ReactElement {
  return (
    <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 10C4.71667 10 4.47917 9.90417 4.2875 9.7125C4.09583 9.52083 4 9.28333 4 9C4 8.71667 4.09583 8.47917 4.2875 8.2875C4.47917 8.09583 4.71667 8 5 8H11C11.2833 8 11.5208 8.09583 11.7125 8.2875C11.9042 8.47917 12 8.71667 12 9C12 9.28333 11.9042 9.52083 11.7125 9.7125C11.5208 9.90417 11.2833 10 11 10H5ZM5 6C4.71667 6 4.47917 5.90417 4.2875 5.7125C4.09583 5.52083 4 5.28333 4 5C4 4.71667 4.09583 4.47917 4.2875 4.2875C4.47917 4.09583 4.71667 4 5 4H11C11.2833 4 11.5208 4.09583 11.7125 4.2875C11.9042 4.47917 12 4.71667 12 5C12 5.28333 11.9042 5.52083 11.7125 5.7125C11.5208 5.90417 11.2833 6 11 6H5ZM2 12H9.5C9.98333 12 10.4333 12.1042 10.85 12.3125C11.2667 12.5208 11.6167 12.8167 11.9 13.2L14 15.95V2H2V12ZM2 18H13.05L10.325 14.425C10.225 14.2917 10.1042 14.1875 9.9625 14.1125C9.82083 14.0375 9.66667 14 9.5 14H2V18ZM14 20H2C1.45 20 0.979167 19.8042 0.5875 19.4125C0.195833 19.0208 0 18.55 0 18V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H14C14.55 0 15.0208 0.195833 15.4125 0.5875C15.8042 0.979167 16 1.45 16 2V18C16 18.55 15.8042 19.0208 15.4125 19.4125C15.0208 19.8042 14.55 20 14 20Z"
        fill="#D8D8D8"
      />
    </svg>
  );
}

export default SummaryReportIconSidebarMenuSVG;
