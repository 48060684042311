import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { axiosInstance } from 'shared/api/http-common';

type AdminTasksState = {
  isAnalystOrAuditorBatchedAssignmentLoading: boolean;
  selectedTasksForAssignment: Array<number | string>;
};

const initialState: AdminTasksState = {
  isAnalystOrAuditorBatchedAssignmentLoading: false,
  selectedTasksForAssignment: [],
};

export const adminTasksSlice = createSlice({
  name: 'adminTasks',
  initialState,
  reducers: {
    setIsAnalystOrAuditorBatchedAssignmentLoading: (state, action) => {
      state.isAnalystOrAuditorBatchedAssignmentLoading = action.payload;
    },
    setSelectedTasksForAssignment: (state, action) => {
      state.selectedTasksForAssignment = action.payload;
    },
  },
});

export const {
  setIsAnalystOrAuditorBatchedAssignmentLoading,
  setSelectedTasksForAssignment
} = adminTasksSlice.actions;

export const assignUserOnAllSelectedTasks =
  ({ t, runIdArr, personAssignedId, currentUserRole, progressValue, additionalActionAfterSuccess }) =>
  async (dispatch) => {
    dispatch(setIsAnalystOrAuditorBatchedAssignmentLoading(true));

    const taskUpdatedArr = runIdArr.map((runId) => ({
      id: runId,
      [currentUserRole]: personAssignedId,
      [`${currentUserRole}_progress`]: progressValue
    }));

    try {
      const response = await axiosInstance.patch('runs', taskUpdatedArr);
      
      if (response.data) {
        toast.success(t(`api_errors.tasks.${currentUserRole}_assignment.all_tasks_success`));

        if (additionalActionAfterSuccess) additionalActionAfterSuccess();
      } else {
        toast.error(t(`api_errors.tasks.${currentUserRole}_assignment.all_tasks_fail`));
      }
    } catch (err) {
      toast.error(t(`api_errors.tasks.${currentUserRole}_assignment.all_tasks_error`));
    }
    dispatch(setIsAnalystOrAuditorBatchedAssignmentLoading(false));
  };

export const selectIsAnalystOrAuditorBatchedAssignmentLoading = (state): boolean =>
  state.adminTasks.isAnalystOrAuditorBatchedAssignmentLoading;
export const selectSelectedTasksForAssignment = (state): Array<number | string> =>
  state.adminTasks.selectedTasksForAssignment;

export default adminTasksSlice.reducer;
