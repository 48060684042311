import { ReactElement } from 'react';

function ClearLineIconSVG(): ReactElement {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 13L13 0.646446" stroke="#949494" />
    </svg>
  );
}

export default ClearLineIconSVG;
