import { ReactElement } from 'react';

function RailIconSidebarMenuSVG(): ReactElement {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 12L12 1M17 6L6 17M2 9L9 16M5.5 5.5L12.5 12.5M9 2L16 9" stroke="#D8D8D8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default RailIconSidebarMenuSVG;
