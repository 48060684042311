import { ReactElement } from 'react';

interface CloseIconProps {
  svgClassName?: string;
  onClick?: () => void;
}

function CLoseIconSVG({ svgClassName, onClick }: CloseIconProps): ReactElement {
  return (
    <svg
      className={svgClassName}
      onClick={onClick}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 1L13 13M1 13L13 1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default CLoseIconSVG;
