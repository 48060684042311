import { ReactElement, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { selectCurrentUserRole } from 'app/store/authReducer';

function PrivateRoute({ children, userRoles, redirectToOnError = '' }): ReactElement {
  const navigate = useNavigate();
  const { state }: any = useLocation();

  const currentUserRole = useSelector(selectCurrentUserRole);

  useEffect(() => {
    if (!userRoles.includes(currentUserRole)) {
      const redirectTo = state?.from || redirectToOnError;
      navigate(redirectTo, { replace: true });
    }
  }, [currentUserRole]);
  return children;
}

export default PrivateRoute;
