/* eslint-disable no-underscore-dangle */
export const {
  REACT_APP_BACKEND_URL,
  REACT_APP_AWS_REGION,
  REACT_APP_AWS_USER_POOL_ID,
  REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
  REACT_APP_AWS_OAUTH_DOMAIN,
  REACT_APP_AWS_OAUTH_REDIRECT_SIGNIN_SIGNOUT,
  REACT_APP_GOOGLE_MAP_API_KEY,
  REACT_APP_GOOGLE_MAP_API_MAP_ID,
  REACT_APP_RUM_IDENTITY_POOL_ID,
  REACT_APP_RUM_ENDPOINT,
  REACT_APP_RUM_GUEST_ROLE_ARN,
  REACT_APP_RUM_APPLICATION_ID,
  REACT_APP_RUM_APPLICATION_VERSION,
  REACT_APP_RUM_APPLICATION_REGION,
} = process.env;

console.log('process.env: ', process.env);
