import { ReactElement } from 'react';

function GoogleMapToOpenArrowBtnSVG(): ReactElement {
  return (
    <svg width="17" height="8" viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.5 7L7.5 1L13.5 7"
        stroke="#949494"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default GoogleMapToOpenArrowBtnSVG;
