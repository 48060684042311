import { ReactElement } from 'react';

function InformationIconSVG({ color = '#949494' }): ReactElement {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM11 9V14H9V9H11ZM11 7V5H9V7H11Z"
        fill={color}
      />
    </svg>
  );
}

export default InformationIconSVG;
