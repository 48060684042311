import { ReactElement } from 'react';

function TrackLocationsTableDescSortBtnIconSVG({ color }): ReactElement {
  return (
    <svg width="8" height="12" viewBox="8 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.625 3.85714L4.625 1M4.625 1L7.625 3.85714M4.625 1V11M15.625 8.14286L12.625 11M12.625 11L9.625 8.14286M12.625 11V1"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default TrackLocationsTableDescSortBtnIconSVG;
