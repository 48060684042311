import { ReactElement } from 'react';

function ViewEditSuspectClickIconSVG(): ReactElement {
  return (
    <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.96781 7.24187C7.79988 7.24179 7.6388 7.30841 7.51998 7.42708C7.40116 7.54575 7.33434 7.70676 7.33422 7.87469V17.4164C7.3342 17.4996 7.35057 17.582 7.38241 17.6589C7.41424 17.7358 7.46091 17.8056 7.51975 17.8645C7.57859 17.9233 7.64844 17.97 7.72532 18.0018C7.8022 18.0336 7.8846 18.05 7.96781 18.05C8.12578 18.05 8.25953 18 8.3675 17.9169L9.95938 16.6247L11.343 19.6417C11.4511 19.8669 11.6763 20 11.918 20C12.0095 20 12.1011 20 12.1927 19.9334L14.493 18.8666C14.8092 18.7166 14.9594 18.3331 14.7927 18.0248L13.4011 15L15.4092 14.6253C15.5428 14.5831 15.668 14.5252 15.7681 14.4086C15.9927 14.1419 15.9597 13.75 15.668 13.5083L8.38469 7.38328L8.37609 7.39203C8.26228 7.29486 8.11746 7.2416 7.96781 7.24187ZM11.5011 8.33313V6.66687H15.668V8.33313H11.5011ZM10.5264 3.96672L12.8842 1.60828L14.0597 2.78328L11.7013 5.14156L10.5263 3.96656L10.5264 3.96672ZM7.33438 0H9.00109V4.16687H7.33422V0H7.33438ZM2.27625 12.2169L4.63469 9.85844L5.80969 11.0334L3.45109 13.3916L2.27609 12.2166L2.27625 12.2169ZM2.27609 2.78312L3.45109 1.60812L5.80953 3.96656L4.63453 5.14156L2.27625 2.78312H2.27609ZM4.83422 8.33297H0.667969V6.66687H4.83422V8.33313"
        fill="#949494"
      />
    </svg>
  );
}

export default ViewEditSuspectClickIconSVG;
