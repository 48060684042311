import { ReactElement, Suspense, useCallback, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { ToastContainer } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Auth } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import { useCookies } from 'react-cookie';

import Layout from 'app/layout';
import Pages from 'pages';
import { AUTHORIZATION_HEADER, axiosInstance } from 'shared/api/http-common';
import withStore from './providers/withStore';
import withAwsRum from './providers/withAwsRum';
import withBrowserRouter from './providers/withBrowserRouter';
import {
  fetchUserConfig,
  selectAllUserInfoFromCognito,
  setAllUserInfoFromCognito,
} from './store/authReducer';
import 'shared/utils/injectHacksForGoogleMapCanvasType';

import styles from './index.module.scss';

const ONE_HOUR_IN_MILLISECONDS = 3600000;

function App(): ReactElement {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const allUserInfoFromCognito = useSelector(selectAllUserInfoFromCognito);

  const [_cookiesIdToken, setCookiesIdToken] = useCookies(['idToken']);

  const authorizeUser = useCallback(async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      console.log('user: ', user);
      const idToken = user.getSignInUserSession().getIdToken().getJwtToken();
      const accessToken = user.getSignInUserSession().getAccessToken().getJwtToken();
      localStorage.setItem('idToken', idToken);
      const expireCookieTime = Date.now() + ONE_HOUR_IN_MILLISECONDS;

      setCookiesIdToken('idToken', idToken, {
        path: '/',
        domain: window.location.hostname.replace('viewer', ''),
        expires: new Date(expireCookieTime),
      });

      localStorage.setItem('accessToken', accessToken);
      axiosInstance.defaults.headers[AUTHORIZATION_HEADER] = `Bearer ${idToken}`;

      dispatch(
        fetchUserConfig(t, {
          additionalActionAtTheEnd: () => dispatch(setAllUserInfoFromCognito(user)),
        })
      );
    } catch (err) {
      Auth.federatedSignIn();
    }
  }, []);

  useEffect(() => {
    authorizeUser();
  }, []);

  if (!allUserInfoFromCognito)
    return (
      <div className={styles.App__suspenseLoader}>
        <CircularProgress />
      </div>
    );

  return (
    <Layout>
      <ToastContainer position="top-right" autoClose={1000} />
      <Suspense
        fallback={
          <div className={styles.App__suspenseLoader}>
            <CircularProgress />
          </div>
        }
      >
        <Pages />
      </Suspense>
    </Layout>
  );
}

export default withAwsRum(withStore(withBrowserRouter(App)));
