import { ReactElement } from 'react';

function TrackLocationsTableFilterBtnIconSVG({ color = '#949494' }): ReactElement {
  return (
    <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.875 1H14.875M2.875 5H12.875M5.875 9H9.875"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default TrackLocationsTableFilterBtnIconSVG;
