import { ReactElement } from 'react';

function NoCameraIconSVG(): ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 48 48">
      <g transform="translate(0,48) scale(0.1,-0.1)" fill="#F9F9F9">
        <path d="M40 433 c0 -4 75 -83 168 -176 l167 -167 -160 2 -160 3 -3 119 c-2 78 1 123 9 132 9 11 8 14 -4 14 -23 0 -27 -22 -27 -145 0 -90 3 -117 16 -129 13 -13 44 -16 181 -16 141 0 169 -3 185 -17 21 -19 37 -11 19 10 -9 11 -8 17 4 27 12 10 15 37 15 131 0 136 -6 149 -74 149 -35 0 -46 5 -64 30 -20 26 -29 30 -72 30 -43 0 -52 -4 -72 -30 -12 -16 -27 -30 -33 -30 -6 0 -26 16 -45 35 -33 34 -50 43 -50 28z m258 -43 c12 -19 10 -20 -43 -20 -30 0 -55 -4 -55 -10 0 -5 49 -11 113 -12 l112 -3 3 -119 c2 -73 -1 -123 -8 -130 -7 -7 -24 3 -54 33 -40 39 -43 46 -39 82 5 33 1 42 -26 70 -27 26 -37 30 -69 26 -28 -3 -43 1 -59 15 l-22 20 22 34 c20 30 27 34 67 34 34 0 48 -5 58 -20z m-11 -122 c21 -20 32 -68 16 -68 -10 0 -83 72 -83 82 0 16 45 7 67 -14z" />
        <path d="M157 253 c-4 -3 -7 -19 -7 -35 0 -43 50 -90 92 -86 17 2 33 8 35 15  3 8 -6 10 -29 7 -46 -8 -84 31 -75 76 6 28 -1 38 -16 23z" />
      </g>
    </svg>
  );
}

export default NoCameraIconSVG;
