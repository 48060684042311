import { createSlice } from '@reduxjs/toolkit';

export interface ZoomUhInterface {
  rail: number;
  pulseCountStart: number;
  pulseCountEnd: number;
}

export const zoomUhSlice = createSlice({
  name: 'zoomUhReducer',
  initialState: {
    zoomUhModal: null,
  },
  reducers: {
    setZoomUhModal: (state, action) => {
      state.zoomUhModal = action.payload;
    },
  },
});

export const { setZoomUhModal } = zoomUhSlice.actions;

export const selectZoomUhModal = (state): ZoomUhInterface | null => state.zoomUh.zoomUhModal;

export default zoomUhSlice.reducer;
