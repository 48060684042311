import { ReactElement } from 'react';

function RecognitionsListIconSidebarMenuSVG(): ReactElement {
  return (
    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M7 0.25C6.0335 0.25 5.25 1.0335 5.25 2V4C5.25 4.9665 6.0335 5.75 7 5.75H16C16.9665 5.75 17.75 4.9665 17.75 4V2C17.75 1.0335 16.9665 0.25 16 0.25H7ZM6.75 2C6.75 1.86193 6.86193 1.75 7 1.75H16C16.1381 1.75 16.25 1.86193 16.25 2V4C16.25 4.13807 16.1381 4.25 16 4.25H7C6.86193 4.25 6.75 4.13807 6.75 4V2Z" fill="#D8D8D8"/>
      <path d="M1.5 4.5C2.32843 4.5 3 3.82843 3 3C3 2.17157 2.32843 1.5 1.5 1.5C0.671573 1.5 0 2.17157 0 3C0 3.82843 0.671573 4.5 1.5 4.5Z" fill="#D8D8D8"/>
      <path d="M3 11C3 11.8284 2.32843 12.5 1.5 12.5C0.671573 12.5 0 11.8284 0 11C0 10.1716 0.671573 9.5 1.5 9.5C2.32843 9.5 3 10.1716 3 11Z" fill="#D8D8D8"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M7 8.25C6.0335 8.25 5.25 9.0335 5.25 10V12C5.25 12.9665 6.0335 13.75 7 13.75H16C16.9665 13.75 17.75 12.9665 17.75 12V10C17.75 9.0335 16.9665 8.25 16 8.25H7ZM6.75 10C6.75 9.86193 6.86193 9.75 7 9.75H16C16.1381 9.75 16.25 9.86193 16.25 10V12C16.25 12.1381 16.1381 12.25 16 12.25H7C6.86193 12.25 6.75 12.1381 6.75 12V10Z" fill="#D8D8D8"/>
    </svg>
  );
}

export default RecognitionsListIconSidebarMenuSVG;
