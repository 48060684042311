import { ReactElement } from 'react';

function TopArrowIconSVG(): ReactElement {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.6017 19.6H6.40167V9.99972H0.59375L10.0017 0.591797L19.4096 9.99972H13.6017V19.6Z"
        fill="#F2F2F2"
      />
    </svg>
  );
}

export default TopArrowIconSVG;
