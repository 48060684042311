const adminRoute = '/admin';

export const adminRoutes = {
  ADMIN_USERS: '/users',
  ADMIN_CUSTOMERS: '/railroads',
  ADMIN_CUSTOMER_CUSTOMER_CODES: 'railroads/:customerId/customer-codes',
  ADMIN_TASKS: '/tasks',
};

const AllRoutes = {
  HOME: '/',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  RUNS: '/runs',
  RUN_T1K: '/run/:t1k',
  RUN_T1K_VIEWER: '/run/:t1k/viewer',
  RUNS_RUNID: '/runs/:runId',
  RUNS_RUNID_VIEWER: '/runs/:runId/viewer',
  RUN_SUMMARY_REPORT: '/runs/:runId/summary-report',
  CHART: '/chart',
  NOT_FOUND: '/404',
  ADMIN: `${adminRoute}/*`,
  ADMIN_USERS: `${adminRoute}${adminRoutes.ADMIN_USERS}`,
  ADMIN_TASKS: `${adminRoute}${adminRoutes.ADMIN_TASKS}`,
  ADMIN_CUSTOMERS: `${adminRoute}${adminRoutes.ADMIN_CUSTOMERS}`,
  ADMIN_CUSTOMER_CUSTOMER_CODES: `${adminRoute}${adminRoutes.ADMIN_CUSTOMER_CUSTOMER_CODES}`,
};

export const AdminRoutes = Object.values(AllRoutes);

export default AllRoutes;
