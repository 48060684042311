import { ReactElement } from 'react';

function WarningIconSVG(): ReactElement {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.7748 17.1883C20.4156 18.4377 19.6112 20.0002 18.3312 20.0002H1.66873C0.385766 20.0002 -0.415015 18.4352 0.224673 17.1883L8.55639 0.937032C9.19717 -0.313594 10.8037 -0.311094 11.4433 0.937032L19.7748 17.1883ZM9.99998 13.8283C9.11795 13.8283 8.40264 14.6327 8.40264 15.6252C8.40264 16.6177 9.11795 17.422 9.99998 17.422C10.882 17.422 11.5973 16.6177 11.5973 15.6252C11.5973 14.6327 10.882 13.8283 9.99998 13.8283ZM8.48327 7.36953L8.74077 12.682C8.75311 12.9305 8.93608 13.1252 9.15702 13.1252H10.843C11.0639 13.1252 11.2469 12.9305 11.2592 12.682L11.5167 7.36953C11.5295 7.10094 11.339 6.87516 11.1005 6.87516H8.89952C8.6603 6.87516 8.47045 7.10094 8.48327 7.36953Z"
        fill="#F13C3C"
      />
    </svg>
  );
}

export default WarningIconSVG;
