import { ReactElement } from 'react';

function CustomersSVG(): ReactElement {
  return (
    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 0C4.89543 0 4 0.89543 4 2V9H6C7.10457 9 8 9.89543 8 11V18H9V9C9 7.89543 9.89543 7 11 7H12V2C12 0.895431 11.1046 0 10 0H6ZM6 4H10V5H6V4ZM10 2H6V3H10V2ZM6 6H8V7H6V6Z"
        fill="currentcolor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 8C10.4477 8 10 8.44772 10 9V18H16V9C16 8.44772 15.5523 8 15 8H11ZM14 10H12V11H14V10ZM14 12H12V13H14V12Z"
        fill="currentcolor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 10C0.447715 10 0 10.4477 0 11V18H7V11C7 10.4477 6.55228 10 6 10H1ZM3 12H2V13H3V12ZM2 14H3V15H2V14ZM4 12H5V13H4V12ZM5 14H4V15H5V14Z"
        fill="currentcolor"
      />
    </svg>
  );
}

export default CustomersSVG;
