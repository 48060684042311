import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { axiosInstance } from 'shared/api/http-common';
import { setUserInfo } from './authReducer';

export const editUserSlice = createSlice({
  name: 'editUser',
  initialState: {
    isEditUserModalLoading: false,
  },
  reducers: {
    setIsEditUserModalLoading: (state, action) => {
      state.isEditUserModalLoading = action.payload;
    },
  },
});

export const { setIsEditUserModalLoading } = editUserSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched

export const uploadAndUpdateUserAvatar =
  (t, newAvatar, additionalActionAfterSuccess: () => void) => async (dispatch, getState) => {
    dispatch(setIsEditUserModalLoading(true));
    try {
      const dataForUpload = new FormData();
      dataForUpload.append('newAvatar', newAvatar);
      const response = await axiosInstance.patch(
        `users/${1}`
        // TODO: change request to update user Avatar and CHECK if we need to
        // add headers to upload image headers:{ 'Content-Type':'multipart/ form-data' }
      );
      if (response.data) {
        const { userInfo } = getState().auth;
        dispatch(setUserInfo({ ...userInfo, avatar: newAvatar }));
        toast.success(t('api_errors.edit_user_profile.patch_success'));
        if (additionalActionAfterSuccess) additionalActionAfterSuccess();
      } else {
        toast.error(t('api_errors.edit_user_profile.patch_fail'));
      }
    } catch (err) {
      toast.error(t('api_errors.edit_user_profile.patch_error'));
    }
    dispatch(setIsEditUserModalLoading(false));
  };

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectIsEditUserModalLoading = (state): boolean =>
  state.editUser.isEditUserModalLoading;

export default editUserSlice.reducer;
