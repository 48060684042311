import { ReactElement } from 'react';

function ValidationWarningIconSVG(): ReactElement {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_10675_4456)">
        <path
          d="M9.90065 31.666H30.1307C30.6023 31.6627 31.0658 31.545 31.4809 31.323C31.8961 31.101 32.2502 30.7815 32.5124 30.3924C32.7746 30.0033 32.937 29.5564 32.9853 29.0907C33.0336 28.6251 32.9664 28.1547 32.7896 27.7207L22.53 10.1462C22.2801 9.69775 21.9137 9.32392 21.469 9.06362C21.0243 8.80331 20.5175 8.66602 20.0013 8.66602C19.485 8.66602 18.9782 8.80331 18.5335 9.06362C18.0888 9.32392 17.7224 9.69775 17.4725 10.1462L7.21294 27.7207C7.03946 28.1448 6.97066 28.6039 7.0123 29.0597C7.05394 29.5155 7.20481 29.9548 7.45231 30.341C7.69981 30.7271 8.03676 31.0489 8.43502 31.2794C8.83329 31.5099 9.28132 31.6424 9.7417 31.666M20.0002 17.5007V21.3282M20.0002 26.6527V26.666"
          stroke="#949494"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_10675_4456">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ValidationWarningIconSVG;
