import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-toastify';

import {
  DEFAULT_SUMMARY_REPORT_DATA,
  SummaryReportData,
} from 'pages/runs/[runId]/viewer/_ui/MovableSidebar/_ui/SummaryReport/_ui/constants';

import { axiosInstance } from 'shared/api/http-common';

type reportsState = {
  isReportsLoading: boolean;
  summaryReportData: SummaryReportData;
  isSummaryReportDataLoading: boolean;
};

const INITIAL_REPORTS_STATE: reportsState = {
  isReportsLoading: false,
  summaryReportData: DEFAULT_SUMMARY_REPORT_DATA,
  isSummaryReportDataLoading: false,
};

export const reportsSlice = createSlice({
  name: 'reportsReducer',
  initialState: INITIAL_REPORTS_STATE,
  reducers: {
    setIsReportsLoading: (state, action) => {
      state.isReportsLoading = action.payload;
    },
    setSummaryReportData: (state, action) => {
      state.summaryReportData = action.payload;
    },
    setIsSummaryReportDataLoading: (state, action) => {
      state.isSummaryReportDataLoading = action.payload;
    },
    resetSummaryReportToInitialState: () => INITIAL_REPORTS_STATE,
  },
});

export const {
  setIsReportsLoading,
  setSummaryReportData,
  setIsSummaryReportDataLoading,
  resetSummaryReportToInitialState,
} = reportsSlice.actions;

export const fetchReports =
  ({ runId }) =>
  async (dispatch, getState) => {
    dispatch(setIsReportsLoading(true));

    try {
      const link = document.createElement('a');

      const trackLocationsReportUrl = await axiosInstance.get(`reports/${runId}/track_locations`);

      if (trackLocationsReportUrl.data) {
        const trackLocationsReportUrlResponse = await axios.get(trackLocationsReportUrl.data, {
          withCredentials: true,
        });

        try {
          if (trackLocationsReportUrlResponse.data) {
            link.href = URL.createObjectURL(
              new Blob([trackLocationsReportUrlResponse.data], { type: 'text/xml' })
            );
            link.download = `${getState().viewer.runInfo.recording}_testsegments.xml`;
            link.click();
          } else {
            toast.error('Failed to download track locations report');
          }
        } catch {
          toast.error('Failed to download track locations report');
        }

        try {
          const suspectsReportUrl = await axiosInstance.get(`reports/${runId}/suspects`);

          if (suspectsReportUrl.data) {
            try {
              const suspectsReportUrlResponse = await axios.get(suspectsReportUrl.data, {
                withCredentials: true,
              });

              if (suspectsReportUrlResponse.data) {
                link.href = URL.createObjectURL(
                  new Blob([suspectsReportUrlResponse.data], { type: 'text/xml' })
                );
                link.download = `${getState().viewer.runInfo.recording}_suspects.xml`;
                link.click();
              } else {
                toast.error('Failed to download suspects report');
              }
            } catch {
              toast.error('Failed to download suspects report');
            }
          } else {
            toast.error('Failed to download suspects report');
          }
        } catch {
          toast.error('Failed to download suspects report');
        }
      } else {
        toast.error('Failed to download reports');
      }
    } catch (_err) {
      toast.error('Failed to download reports');
    }

    dispatch(setIsReportsLoading(false));
  };

export const getReportDataAsync =
  ({ runId, t }) =>
  async (dispatch) => {
    dispatch(setIsSummaryReportDataLoading(true));

    try {
      // TODO check endpoint naming
      const response = await axiosInstance.get(`reports/${runId}/summary-reports`);
      if (response.data) {
        // TODO return DEFAULT_SUMMARY_REPORT_DATA before deployment
        // for not to confuse the user by the same data on each run
        dispatch(setSummaryReportData(response.data));
      } else {
        toast.error(t('api_errors.summary_report.get.fail'));
      }
    } catch (err) {
      toast.error(t('api_errors.summary_report.get.error'));
    }

    dispatch(setIsSummaryReportDataLoading(false));
  };

export const selectIsReportsLoading = (state): boolean => state.reports.isReportsLoading;
export const selectSummaryReportData = (state): SummaryReportData =>
  state.reports.summaryReportData;
export const selectIsSummaryReportDataLoading = (state): boolean =>
  state.reports.isSummaryReportDataLoading;

export default reportsSlice.reducer;
