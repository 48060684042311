import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { axiosInstance } from 'shared/api/http-common';
import userConfigDefault from 'shared/userConfig.json';
import { allUserRolesEnum } from 'shared/config/roles';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    userInfo: {},
    userRoles: [],
    userUniqueId: '',
    currentUserRole: '',
    allUserInfoFromCognito: null,
    userConfig: {},
    isUserConfigLoading: false,
    userConfigError: null,
    // isLoginFormLoading: false,
    // loginFormError: null,
    // isSignOutLoading: false,
    // signOutError: null,
    // isForgotPasswordLoading: false,
    // isForgotPasswordSuccess: false,
    // forgotPasswordError: null,
    // isResetPasswordLoading: false,
    // isResetPasswordSuccess: false,
  },
  reducers: {
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    // setIsLoginFormLoading: (state, action) => {
    //   state.isLoginFormLoading = action.payload;
    // },
    // setLoginFormError: (state, action) => {
    //   state.loginFormError = action.payload;
    // },
    // setIsSignOutLoading: (state, action) => {
    //   state.isSignOutLoading = action.payload;
    // },
    // setSignOutError: (state, action) => {
    //   state.signOutError = action.payload;
    // },
    // setIsForgotPasswordLoading: (state, action) => {
    //   state.isForgotPasswordLoading = action.payload;
    // },
    // setIsForgotPasswordSuccess: (state, action) => {
    //   state.isForgotPasswordSuccess = action.payload;
    // },
    // setForgotPasswordError: (state, action) => {
    //   state.forgotPasswordError = action.payload;
    // },
    // setIsResetPasswordLoading: (state, action) => {
    //   state.isResetPasswordLoading = action.payload;
    // },
    // setIsResetPasswordSuccess: (state, action) => {
    //   state.isResetPasswordSuccess = action.payload;
    // },
    setUserRoles: (state, action) => {
      state.userRoles = action.payload;
    },
    setCurrentUserRole: (state, action) => {
      state.currentUserRole = action.payload;
    },
    setAllUserInfoFromCognito: (state, action) => {
      const userRoles = action.payload.signInUserSession?.idToken?.payload['cognito:groups'] || [
        'customer',
      ];
      const userUniqueId = action.payload.signInUserSession?.idToken?.payload?.uuid;
      if (userRoles.includes(localStorage.getItem('currentUserRole'))) {
        state.currentUserRole = localStorage.getItem('currentUserRole') || '';
      } else {
        // eslint-disable-next-line prefer-destructuring
        state.currentUserRole = userRoles[0];
      }
      state.userRoles = userRoles;
      state.userUniqueId = userUniqueId;
      state.allUserInfoFromCognito = action.payload;
    },
    setUserConfig: (state, action) => {
      state.userConfig = action.payload;
    },
    setIsUserConfigLoading: (state, action) => {
      state.isUserConfigLoading = action.payload;
    },
    setUserConfigError: (state, action) => {
      state.userConfigError = action.payload;
    },
  },
});

export const {
  setUserInfo,
  // setIsLoginFormLoading,
  // setIsSignOutLoading,
  // setLoginFormError,
  // setSignOutError,
  // setIsForgotPasswordLoading,
  // setIsForgotPasswordSuccess,
  // setForgotPasswordError,
  // setIsResetPasswordLoading,
  // setIsResetPasswordSuccess,
  setUserConfig,
  setIsUserConfigLoading,
  setUserConfigError,
  setUserRoles,
  setCurrentUserRole,
  setAllUserInfoFromCognito,
} = authSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched

export const fetchUserConfig =
  (t, { additionalActionAtTheEnd }) =>
  async (dispatch) => {
    dispatch(setIsUserConfigLoading(true));
    try {
      const response = await axiosInstance.get('/users/config');
      if (response.data) {
        dispatch(setUserConfig(response.data));
        dispatch(setUserConfigError(null));
      } else {
        toast.warn(t('api_errors.auth.user_config_warn'));
        dispatch(
          setUserConfigError({
            error_text: 'used default user config',
            error_value: userConfigDefault,
          })
        );
        dispatch(setUserConfig(userConfigDefault));
      }
    } catch (err) {
      toast.warn(t('api_errors.auth.user_config_warn'));
      dispatch(setUserConfigError(err));
      dispatch(setUserConfig(userConfigDefault));
    }
    dispatch(setIsUserConfigLoading(false));
    if (additionalActionAtTheEnd) additionalActionAtTheEnd();
  };

// export const forgotPasswordByBackend = () => async (dispatch) => {
//   dispatch(setIsForgotPasswordLoading(true));
//   try {
//     // TODO change to forgot-password
//     const response = await axiosInstance.post('posts');
//     if (response.data) {
//       dispatch(setIsForgotPasswordSuccess(true));
//     } else {
//       // TODO redo and parse error in more correct way
//       dispatch(setForgotPasswordError('Some Error'));
//       toast.error('Forgot password failed');
//     }
//   } catch (err) {
//     dispatch(setForgotPasswordError('Some Error'));
//     toast.error('Forgot password failed');
//   }
//   dispatch(setIsForgotPasswordLoading(false));
// };

// export const resetPasswordByBackend = () => async (dispatch) => {
//   dispatch(setIsResetPasswordLoading(true));
//   try {
//     // TODO change to reset-password
//     const response = await axiosInstance.post('posts');
//     if (response.data) {
//       dispatch(setIsResetPasswordSuccess(true));
//     } else {
//       toast.error('Failed to reset password, please try again');
//     }
//   } catch (err) {
//     toast.error('Failed to reset password, please try again');
//   }
//   dispatch(setIsResetPasswordLoading(false));
// };

// export const selectIsSignOutLoading = (state): boolean => state.auth.isSignOutLoading;
// export const selectSignOutError = (state): any => state.auth.signOutError;

// export const selectIsForgotPasswordLoading = (state): boolean => state.auth.isForgotPasswordLoading;
// export const selectIsForgotPasswordSuccess = (state): boolean => state.auth.isForgotPasswordSuccess;
// export const selectForgotPasswordError = (state): any => state.auth.forgotPasswordError;
// export const selectIsResetPasswordLoading = (state): boolean => state.auth.isResetPasswordLoading;
// export const selectIsResetPasswordSuccess = (state): boolean => state.auth.isResetPasswordSuccess;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
// export const selectIsAuthenticated = (state): boolean => state.auth.isAuthenticated;

export const selectUserInfo = (state): any => state.auth.userInfo;
export const selectUserRoles = (state): [string] => state.auth.userRoles;
export const selectUserUniqueId = (state): [string] => state.auth.userUniqueId;
export const selectCurrentUserRole = (state): allUserRolesEnum => state.auth.currentUserRole;

export const selectIsLoginFormLoading = (state): boolean => state.auth.isLoginFormLoading;
export const selectLoginFormError = (state): any => state.auth.loginFormError;

export const selectAllUserInfoFromCognito = (state): any => state.auth.allUserInfoFromCognito;

export const selectUserConfig = (state): any => state.auth.userConfig;
export const selectIsUserConfigLoading = (state): any => state.auth.isUserConfigLoading;
export const selectUserConfigError = (state): any => state.auth.userConfigError;

export default authSlice.reducer;
