import adminTasksReducer from './adminTasksReducer';
import adminUsersReducer from './adminUsersReducer';
import authReducer from './authReducer';
import editUserReducer from './editUserReducer';
import adminCustomersReducer from './adminCustomersReducer';
import adminCustomerCodesReducer from './adminCustomerCodesReducer';
import runsReducer from './runsReducer';
import viewerReducer from './viewerReducer';
import tscanReducer from './tscanReducer';
import currentSuspectInModalReducer from './currentSuspectInModalReducer';
import libraryTrackLocationsReducer from './libraryTrackLocationsReducer';
import reportsReducer from './reportsReducer';
import zoomUhReducer from './zoomUhReducer';
import googleMapsReducer from './googleMapsReducer';

const rootReducer = {
  editUser: editUserReducer,
  auth: authReducer,
  viewer: viewerReducer,
  runs: runsReducer,
  adminUsers: adminUsersReducer,
  adminTasks: adminTasksReducer,
  adminCustomers: adminCustomersReducer,
  adminCustomerCodes: adminCustomerCodesReducer,
  tscan: tscanReducer,
  currentSuspectInModal: currentSuspectInModalReducer,
  libraryTrackLocations: libraryTrackLocationsReducer,
  reports: reportsReducer,
  zoomUh: zoomUhReducer,
  googleMaps: googleMapsReducer,
};

export default rootReducer;
