import { ReactElement } from 'react';

function InputSubmitArrowSVG({ color = '#1A1A1A' }): ReactElement {
  return (
    <svg width="20" height="20" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.5731 7.77887L7.63931 11.5595C7.66468 11.6116 7.70458 11.6553 7.75421 11.6852C7.80384 11.7152 7.86107 11.7301 7.91901 11.7283C7.97741 11.7265 8.03409 11.7081 8.08241 11.6752C8.13074 11.6424 8.16871 11.5965 8.19191 11.5428L11.4672 1.37236L1.60395 5.48182C1.55241 5.50942 1.50981 5.55116 1.48117 5.60213C1.45252 5.65311 1.43902 5.71119 1.44224 5.76958C1.44522 5.82747 1.46489 5.88326 1.49886 5.93022C1.53284 5.97719 1.57967 6.01332 1.63372 6.03427L5.5731 7.77887Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default InputSubmitArrowSVG;
