import { ReactElement } from 'react';

function LogoSVG({ additionalClassName }): ReactElement {
  return (
    <svg
      className={additionalClassName}
      width="48"
      height="32"
      viewBox="0 0 48 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M45.3176 23.6707H45.4353C45.5529 23.6707 45.6706 23.6236 45.6706 23.5295C45.6706 23.4354 45.6 23.3883 45.4588 23.3883C45.3882 23.3883 45.3647 23.3883 45.3411 23.4118V23.6707H45.3176ZM45.3176 24.2118H45.0823V23.2236C45.1764 23.2001 45.2941 23.2001 45.4588 23.2001C45.647 23.2001 45.7176 23.2236 45.7882 23.2707C45.8588 23.3177 45.8823 23.3883 45.8823 23.4824C45.8823 23.6001 45.7882 23.6707 45.6706 23.7177V23.7413C45.7647 23.7648 45.8117 23.8589 45.8353 23.9766C45.8588 24.1177 45.8823 24.1883 45.9058 24.2118H45.6706C45.647 24.1648 45.6235 24.0942 45.6 23.9766C45.5764 23.8824 45.5294 23.8354 45.3882 23.8354H45.2706L45.3176 24.2118ZM44.7058 23.7177C44.7058 24.1648 45.0353 24.4942 45.4823 24.4942C45.9058 24.4942 46.2353 24.1413 46.2353 23.7177C46.2353 23.2707 45.9058 22.9177 45.4823 22.9177C45.0353 22.9177 44.7058 23.2707 44.7058 23.7177ZM46.4706 23.7177C46.4706 24.2589 46.0235 24.706 45.4588 24.706C44.8941 24.706 44.447 24.2589 44.447 23.7177C44.447 23.1766 44.8941 22.7295 45.4588 22.7295C46.0235 22.7295 46.4706 23.153 46.4706 23.7177Z"
        fill="white"
      />
      <path
        d="M2.75293 29.0117C3.22352 29.294 3.95293 29.5293 4.70587 29.5293C5.64705 29.5293 6.18823 29.0823 6.18823 28.4234C6.18823 27.8117 5.78823 27.4587 4.75293 27.0823C3.41176 26.6117 2.5647 25.9058 2.5647 24.7293C2.5647 23.4117 3.67058 22.3999 5.41176 22.3999C6.28235 22.3999 6.94117 22.5881 7.3647 22.7999L7.01176 23.9764C6.7294 23.8117 6.1647 23.5999 5.41176 23.5999C4.47058 23.5999 4.07058 24.094 4.07058 24.5646C4.07058 25.1764 4.54117 25.4823 5.62352 25.9058C7.03529 26.4234 7.71764 27.1293 7.71764 28.3058C7.71764 29.5999 6.7294 30.7293 4.65882 30.7293C3.81176 30.7293 2.91764 30.4705 2.47058 30.2117L2.75293 29.0117Z"
        fill="white"
      />
      <path
        d="M32.1648 0.564697C25.2942 10.7765 14.0471 16.9412 2.23535 19.8353C13.8824 16.2588 23.2706 9.64705 29.3883 0.564697H32.1648Z"
        fill="#F1C019"
      />
      <path
        d="M37.1765 0.564697C29.8353 11.4588 17.6236 17.3412 7.36475 19.8353C17.953 16.5176 28.6589 9.59999 34.4 0.588227H37.1765"
        fill="#F1C019"
      />
      <path
        d="M42.1882 0.564697C34.9882 11.2235 24.3059 16.8941 12.2588 19.8118C23.7882 16.1882 32.8 10.3294 39.4117 0.564697H42.1882Z"
        fill="#F1C019"
      />
      <path
        d="M44.1647 0.800049C37.5765 10.4471 28.7059 16.2353 17.2706 19.8353C28.0471 17.2 37.9059 12.2824 45.0118 3.67064V2.42358C44.9883 1.7177 44.7295 1.12946 44.1647 0.800049Z"
        fill="#F1C019"
      />
      <path
        d="M44.9882 6.35303C39.1764 12.5648 30.9647 17.0824 23.0117 19.6942H43.1529C44.1647 19.6942 44.9882 18.8707 44.9882 17.8589V6.35303Z"
        fill="#F1C019"
      />
      <path
        d="M27.2 0.564697H2.44708C1.41179 0.564697 0.588257 1.38823 0.588257 2.42352V17.8588C0.588257 18.2118 0.682374 18.5412 0.84708 18.8C11.8588 15.5059 20.6588 9.69411 27.2 0.564697Z"
        fill="#F1C019"
      />
      <path
        d="M9.45883 22.6588C9.97648 22.5647 10.7294 22.4941 11.7177 22.4941C12.8 22.4941 13.5765 22.7294 14.0941 23.153C14.5647 23.553 14.8941 24.1883 14.8941 24.9412C14.8941 25.7177 14.6588 26.353 14.1882 26.7765C13.6 27.3647 12.6824 27.6471 11.6235 27.6471C11.3412 27.6471 11.1059 27.6236 10.8941 27.6V30.6353H9.4353V22.6588H9.45883ZM10.9177 26.4236C11.1059 26.4706 11.3412 26.4941 11.6471 26.4941C12.753 26.4941 13.4118 25.953 13.4118 25.0118C13.4118 24.1177 12.8 23.6236 11.7647 23.6236C11.3647 23.6236 11.0588 23.6706 10.9177 23.6941V26.4236Z"
        fill="white"
      />
      <path
        d="M20.8236 27.0354H17.7647V29.4118H21.1765V30.6118H16.3059V22.5413H21.0118V23.7413H17.7647V25.8354H20.8L20.8236 27.0354Z"
        fill="white"
      />
      <path
        d="M23.1059 22.6588C23.6471 22.5647 24.4942 22.4941 25.3412 22.4941C26.4942 22.4941 27.2942 22.6824 27.8353 23.153C28.2824 23.5294 28.5412 24.0941 28.5412 24.7765C28.5412 25.8118 27.8353 26.5177 27.0824 26.8V26.8471C27.6471 27.0588 28.0001 27.6236 28.2118 28.4C28.4706 29.4118 28.6824 30.353 28.8471 30.6588H27.2942C27.1765 30.4236 26.9883 29.7647 26.753 28.753C26.5177 27.6941 26.1412 27.3647 25.2942 27.3412H24.5412V30.6588H23.0824L23.1059 22.6588ZM24.5648 26.2353H25.4353C26.4236 26.2353 27.0589 25.7177 27.0589 24.8941C27.0589 24 26.4471 23.6 25.4824 23.6C25.0118 23.6 24.7059 23.6471 24.5648 23.6706V26.2353Z"
        fill="white"
      />
      <path
        d="M30.4706 22.706C31.0118 22.6118 31.8588 22.5413 32.7059 22.5413C33.8588 22.5413 34.6588 22.7295 35.2 23.2001C35.6471 23.5766 35.9059 24.1413 35.9059 24.8236C35.9059 25.8589 35.2 26.5648 34.4471 26.8471V26.8942C35.0118 27.106 35.3647 27.6707 35.5765 28.4471C35.8353 29.4589 36.0471 30.4001 36.2118 30.706H34.6823C34.5647 30.4707 34.3765 29.8118 34.1412 28.8001C33.9059 27.7413 33.5294 27.4118 32.6823 27.3883H31.9294V30.706H30.4706V22.706ZM31.9529 26.2824H32.8235C33.8118 26.2824 34.4471 25.7648 34.4471 24.9413C34.4471 24.0471 33.8118 23.6471 32.8706 23.6471C32.4 23.6471 32.0941 23.6942 31.9529 23.7177V26.2824Z"
        fill="white"
      />
      <path
        d="M39.6941 30.5647V27.2L37.1294 22.4941H38.8L39.7647 24.5647C40.047 25.153 40.2353 25.6236 40.4706 26.1647H40.4941C40.7059 25.6471 40.9176 25.153 41.2 24.5647L42.1647 22.4941H43.8353L41.1765 27.153V30.5647H39.6941Z"
        fill="white"
      />
    </svg>
  );
}

export default LogoSVG;
