import { ReactElement } from 'react';
import cn from 'classnames';

import styles from './styles.module.scss';

type SrButtonPropsType = {
  onClick?: () => void;
  title: string | ReactElement;
  disabled?: boolean;
  variant?: string;
  type?: 'submit' | 'reset' | 'button';
  customClassName?: string;
};

export default function SrButton({
  onClick,
  title,
  disabled = false,
  type = 'button',
  customClassName = '',
}: SrButtonPropsType): ReactElement {
  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      disabled={disabled}
      className={cn(styles.SrButton, { [customClassName]: !!customClassName })}
      onClick={onClick}
    >
      {title}
    </button>
  );
}
