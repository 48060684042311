import { ReactElement, useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import ReactFlagsSelect from 'react-flags-select';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';

import {
  selectUserInfo,
  selectUserRoles,
  setCurrentUserRole,
  selectAllUserInfoFromCognito,
  selectCurrentUserRole,
} from 'app/store/authReducer';
import { initialDataForRunsQueryParams, setRunsQueryParams } from 'app/store/runsReducer';
import LanguagesListDropdownIconSVG from 'shared/svgs/LanguagesListDropdownIconSVG';
import SrModal from 'shared/components/SrModal';
import { dateRangeValues } from 'shared/utils/constants/RunsInfoConstants';
import { getFirsLettersFromInitials } from 'shared/utils';
import { allUserRoles, allUserRolesEnum } from 'shared/config/roles';
import AllRoutes from 'shared/config/routes';
import EditUserProfile from './_ui/EditUserProfile';

import styles from './styles.module.scss';

function ProfileContent(): ReactElement {
  const [isUserProfileDropdownOpened, setIsUserProfileDropdownOpened] = useState(false);
  const [isEditUserProfileModalOpened, setIsEditUserProfileModalOpened] = useState(false);

  const dispatch = useDispatch();

  // TODO need to add information about userInfo, take correct info about current logged in userInfo
  const userInfo = useSelector(selectUserInfo);
  const currentUserRole = useSelector(selectCurrentUserRole);
  const userRoles = useSelector(selectUserRoles);
  const allUserInfoFromCognito = useSelector(selectAllUserInfoFromCognito);

  const navigate = useNavigate();

  const { t, i18n } = useTranslation();

  const rolesOptions = useMemo(
    () =>
      allUserRoles.map((userRole) => ({
        value: userRole,
        label: t(`header.roles.${userRole}`),
        isDisabled: !userRoles.includes(userRole),
      })),
    [userRoles, i18n.language]
  );

  const onClickToProfileDropdown = useMemo(
    () => (): void => {
      setIsUserProfileDropdownOpened(false);
      window.removeEventListener('click', onClickToProfileDropdown);
    },
    []
  );

  useEffect(() => {
    if (isUserProfileDropdownOpened) {
      setTimeout(() => window.addEventListener('click', onClickToProfileDropdown));
    }

    return () => {
      window.removeEventListener('click', onClickToProfileDropdown);
    };
  }, [isUserProfileDropdownOpened]);

  const handleRolesSelectChange = (roleOption): void => {
    dispatch(setCurrentUserRole(roleOption.value));
    localStorage.setItem('currentUserRole', roleOption.value);
    dispatch(
      setRunsQueryParams({
        ...initialDataForRunsQueryParams,
        nonstop: roleOption.value !== allUserRolesEnum.DEVELOPER,
        date_range_select:
          roleOption.value === allUserRolesEnum.DEVELOPER
            ? dateRangeValues.EMPTY
            : initialDataForRunsQueryParams.date_range_select,
      })
    );
    if (roleOption.value === allUserRolesEnum.ADMIN) {
      navigate(AllRoutes.ADMIN);
    } else if (roleOption.value === allUserRolesEnum.SUPERVISOR) {
      navigate(AllRoutes.ADMIN_TASKS);
    }
  };

  return (
    <div className={styles.ProfileContent__container}>
      <div className={styles.ProfileContent__languagesWrapper}>
        <ReactFlagsSelect
          className={styles.ProfileContent__languages}
          selected={i18n.language.toUpperCase() === 'EN' ? 'US' : i18n.language.toUpperCase()}
          onSelect={(languageCode) => i18n.changeLanguage(languageCode.toLowerCase())}
          countries={['US', 'PT']}
          customLabels={{ US: '(US) Eng', PT: 'Português' }}
        />
        <LanguagesListDropdownIconSVG
          className={styles.ProfileContent__languagesListDropdownIcon}
        />
      </div>
      <div className={styles.ProfileContent__userInfo}>
        <div className={styles.ProfileContent__userName}>
          <div className={styles.ProfileContent__userGreating}>
            {allUserInfoFromCognito?.signInUserSession?.idToken?.payload?.given_name &&
            allUserInfoFromCognito?.signInUserSession?.idToken?.payload?.family_name
              ? `${allUserInfoFromCognito?.signInUserSession?.idToken?.payload?.given_name}
              ${allUserInfoFromCognito?.signInUserSession?.idToken?.payload?.family_name}`
              : `${t('header.greating')}!`}
          </div>
          <Select
            className={styles.ProfileContent__userRolesSelect}
            options={rolesOptions}
            value={{
              value: currentUserRole,
              label: t(`header.roles.${currentUserRole}`),
            }}
            onChange={handleRolesSelectChange}
            isSearchable={false}
          />
        </div>
        <div className={styles.ProfileContent__userAvatarWrapper}>
          {userInfo.avatar && (
            <img
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
              role="button"
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  setIsUserProfileDropdownOpened(!isUserProfileDropdownOpened);
                }
              }}
              onClick={() => {
                setIsUserProfileDropdownOpened(!isUserProfileDropdownOpened);
              }}
              className={styles.ProfileContent__userAvatarImg}
              src={userInfo.avatar}
              alt={t('header.profile.avatar')}
            />
          )}
          {!userInfo.avatar && (
            <button
              type="button"
              onClick={() => {
                setIsUserProfileDropdownOpened(!isUserProfileDropdownOpened);
              }}
              className={styles.ProfileContent__withoutUserAvatar}
            >
              {allUserInfoFromCognito?.signInUserSession?.idToken?.payload?.given_name &&
              allUserInfoFromCognito?.signInUserSession?.idToken?.payload?.family_name
                ? `${getFirsLettersFromInitials(
                    allUserInfoFromCognito?.signInUserSession?.idToken?.payload?.given_name
                  )}${getFirsLettersFromInitials(
                    allUserInfoFromCognito?.signInUserSession?.idToken?.payload?.family_name
                  )}`
                : 'A'}
            </button>
          )}
          {isUserProfileDropdownOpened && (
            <div className={styles.ProfileContent__dropDownMenu}>
              <button
                type="button"
                onClick={() => {
                  setIsEditUserProfileModalOpened(true);
                  setIsUserProfileDropdownOpened(!isUserProfileDropdownOpened);
                }}
                className={styles.ProfileContent__buttons}
              >
                {t('header.profile.edit_user_profile_button')}
              </button>
              <button
                type="button"
                onClick={() => {
                  Auth.signOut();
                }}
                // TODO: add correct loading instead of false
                disabled={false}
                className={styles.ProfileContent__buttons}
              >
                {t('header.profile.signOut_button')}
              </button>
            </div>
          )}
        </div>
        {isEditUserProfileModalOpened && (
          <SrModal
            contentClassName={styles.ProfileContent__modalContentAdditionalStyles}
            onClose={() => setIsEditUserProfileModalOpened(false)}
            title={t('header.edit_profile_modal.title')}
          >
            <EditUserProfile
              closeEditUserProfileModal={() => setIsEditUserProfileModalOpened(false)}
            />
          </SrModal>
        )}
      </div>
    </div>
  );
}

export default ProfileContent;
