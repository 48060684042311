import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { axiosInstance } from 'shared/api/http-common';

type AdminCustomerCodesState = {
  // TODO: create real type/interface for CustomerCodes
  customerCodesData: any;
  isCustomerCodesDataLoading: boolean;
};

const initialState: AdminCustomerCodesState = {
  customerCodesData: [],
  isCustomerCodesDataLoading: false,
};

export const adminCustomerCodesSlice = createSlice({
  name: 'adminCustomerCodes',
  initialState,
  reducers: {
    setCustomerCodesData: (state, action) => {
      state.customerCodesData = action.payload;
    },
    setIsCustomerCodesDataLoading: (state, action) => {
      state.isCustomerCodesDataLoading = action.payload;
    },
  },
});

export const { setCustomerCodesData, setIsCustomerCodesDataLoading } =
  adminCustomerCodesSlice.actions;

export const getCustomerCodesAsync =
  ({ customerId, t }) =>
  async (dispatch) => {
    dispatch(setIsCustomerCodesDataLoading(true));
    try {
      const response = await axiosInstance.get(`/customers/${customerId}/codes`);
      if (response.data) {
        dispatch(setCustomerCodesData(response.data));
      } else {
        toast.error(t('api_errors.customers.customer_codes.get.fail'));
      }
    } catch (_err) {
      toast.error(t('api_errors.customers.customer_codes.get.error'));
    }
    dispatch(setIsCustomerCodesDataLoading(false));
  };

export const updateCustomerCodesAsync =
  (t, { newCustomerCodes, customerId: _customerId }) =>
  async (dispatch) => {
    dispatch(setIsCustomerCodesDataLoading(true));
    try {
      const response = await axiosInstance.post('users', newCustomerCodes);
      if (response.data) {
        dispatch(setCustomerCodesData(newCustomerCodes));
        toast.success(t('api_errors.customers.customer_codes.update.success'));
      } else {
        toast.error(t('api_errors.customers.customer_codes.update.fail'));
      }
    } catch (_err) {
      toast.error(t('api_errors.customers.customer_codes.update.error'));
    }
    dispatch(setIsCustomerCodesDataLoading(false));
  };

export const deleteCustomerCodesAsync =
  (t, { customerId: _customerId }) =>
  async (dispatch) => {
    dispatch(setIsCustomerCodesDataLoading(true));
    try {
      const response = await axiosInstance.get('users');
      if (response.data) {
        dispatch(setCustomerCodesData([]));
        toast.success(t('api_errors.customers.customer_codes.delete.success'));
      } else {
        toast.error(t('api_errors.customers.customer_codes.delete.fail'));
      }
    } catch (_err) {
      toast.error(t('api_errors.customers.customer_codes.delete.error'));
    }
    dispatch(setIsCustomerCodesDataLoading(false));
  };

export const selectCustomerCodesData = (state): Array<any> =>
  state.adminCustomerCodes.customerCodesData;
export const selectIsCustomerCodesDataLoading = (state): boolean =>
  state.adminCustomerCodes.isCustomerCodesDataLoading;

export default adminCustomerCodesSlice.reducer;
