import { ReactElement } from 'react';

function RunUXChannelsInfoIconSidebarMenuSVG(): ReactElement {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_8535_4525)">
        <path d="M12.8773 10.5477C12.8773 11.0321 12.4846 11.4249 12.0001 11.4249C11.5156 11.4249 11.1229 11.0321 11.1229 10.5477C11.1229 10.0632 11.5156 9.67048 12.0001 9.67048C12.4846 9.67048 12.8773 10.0632 12.8773 10.5477Z" fill="#D8D8D8"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M12.6432 17.684C16.2989 17.3588 19.1649 14.2879 19.1649 10.5476C19.1649 6.59062 15.9571 3.38281 12.0001 3.38281C8.04304 3.38281 4.83524 6.59062 4.83524 10.5476C4.83524 14.2879 7.70124 17.3588 11.357 17.684H5.85865C5.49524 17.684 5.20065 17.9786 5.20065 18.342C5.20065 18.7054 5.49524 19 5.85865 19H18.1413C18.5047 19 18.7993 18.7054 18.7993 18.342C18.7993 17.9786 18.5047 17.684 18.1413 17.684H12.6432ZM12.0001 16.3965C15.2303 16.3965 17.8489 13.7779 17.8489 10.5476C17.8489 7.31742 15.2303 4.69881 12.0001 4.69881C8.76985 4.69881 6.15123 7.31742 6.15123 10.5476C6.15123 13.7779 8.76985 16.3965 12.0001 16.3965Z" fill="#D8D8D8"/>
      </g>
      <defs>
        <clipPath id="clip0_8535_4525">
          <rect width="24" height="24" fill="#D8D8D8"/>
        </clipPath>
      </defs>
    </svg>
  );
}

export default RunUXChannelsInfoIconSidebarMenuSVG;
