import { ReactElement } from 'react';

import { RunRejectionIconSVG } from 'shared/svgs';

import styles from './styles.module.scss';

function ConfirmUnassignActionModal(): ReactElement {
  return (
    <div className={styles.ConfirmUnassignActionModal__wrapper}>
      <div className={styles.ConfirmUnassignActionModal__questionIcon}>
        <RunRejectionIconSVG />
      </div>
      <div className={styles.ConfirmUnassignActionModal__questionText}>
        Are you sure you want to unassign yourself from the run?
      </div>
    </div>
  );
}

export default ConfirmUnassignActionModal;
