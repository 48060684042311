import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { Customer } from 'pages/admin/customers/_ui/constants';

import { axiosInstance } from 'shared/api/http-common';

type AdminCustomersState = {
  customersData: Array<Customer>;
  isCustomersDataLoading: boolean;
  isAddOrEditCustomerRequestLoading: boolean;
  customerDataForEditPanel: Customer | null;
};

const initialState: AdminCustomersState = {
  customersData: [],
  isCustomersDataLoading: false,
  isAddOrEditCustomerRequestLoading: false,
  customerDataForEditPanel: null,
};

export const adminCustomersSlice = createSlice({
  name: 'adminCustomers',
  initialState,
  reducers: {
    setCustomersData: (state, action) => {
      state.customersData = action.payload;
    },
    setIsCustomersDataLoading: (state, action) => {
      state.isCustomersDataLoading = action.payload;
    },
    setIsAddOrEditCustomerRequestLoading: (state, action) => {
      state.isAddOrEditCustomerRequestLoading = action.payload;
    },
    addNewCustomerToCustomersData: (state, action) => {
      state.customersData = [...state.customersData, action.payload];
    },
    updateCustomerAtCustomersData: (state, action) => {
      state.customersData = state.customersData.map((customerData) => {
        if (action.payload.id === customerData.id) {
          return action.payload;
        }
        return customerData;
      });
    },
    setCustomerDataForEditPanel: (state, action) => {
      state.customerDataForEditPanel = action.payload;
    },
    deleteCustomerFromCustomersData: (state, action) => {
      const newCustomersData = state.customersData.filter(
        (customer) => +customer.id !== +action.payload
      );
      state.customersData = newCustomersData;
    },
  },
});

export const {
  setCustomersData,
  setIsCustomersDataLoading,
  addNewCustomerToCustomersData,
  setIsAddOrEditCustomerRequestLoading,
  setCustomerDataForEditPanel,
  updateCustomerAtCustomersData,
  deleteCustomerFromCustomersData,
} = adminCustomersSlice.actions;

export const getCustomersDataAsync = (t) => async (dispatch) => {
  dispatch(setIsCustomersDataLoading(true));
  try {
    const response = await axiosInstance.get('customers');
    if (response.data) {
      // TODO: remove this parsedCustomers when all data will pass correctly to the FE
      const parsedCustomers = response.data.map((customer) => ({
        ...customer,
        corporate_emails: customer.corporate_emails?.flat(),
        corporate_cc_emails: customer.corporate_cc_emails?.flat(),
      }));

      dispatch(setCustomersData(parsedCustomers));
    } else {
      toast.error(t('api_errors.customers.get.fail'));
    }
  } catch (_err) {
    toast.error(t('api_errors.customers.get.error'));
  }
  dispatch(setIsCustomersDataLoading(false));
};

export const addNewCustomerAsync =
  (t, newCustomer, additionalActionAfterSuccess: () => void) => async (dispatch) => {
    dispatch(setIsAddOrEditCustomerRequestLoading(true));
    try {
      const response = await axiosInstance.post('users', newCustomer);
      if (response.data) {
        dispatch(addNewCustomerToCustomersData(newCustomer));
        toast.success(t('api_errors.customers.add.success'));
        if (additionalActionAfterSuccess) additionalActionAfterSuccess();
      } else {
        toast.error(t('api_errors.customers.add.fail'));
      }
    } catch (_err) {
      toast.error(t('api_errors.customers.add.error'));
    }
    dispatch(setIsAddOrEditCustomerRequestLoading(false));
  };

export const updateCustomerAsync =
  (t, newCustomer, additionalActionAfterSuccess: () => void) => async (dispatch) => {
    dispatch(setIsAddOrEditCustomerRequestLoading(true));
    try {
      const response = await axiosInstance.post('users', newCustomer);
      if (response.data) {
        dispatch(updateCustomerAtCustomersData(newCustomer));
        toast.success(t('api_errors.customers.update.success'));
        if (additionalActionAfterSuccess) additionalActionAfterSuccess();
      } else {
        toast.error(t('api_errors.customers.update.fail'));
      }
    } catch (_err) {
      toast.error(t('api_errors.customers.update.error'));
    }
    dispatch(setIsAddOrEditCustomerRequestLoading(false));
  };

// TODO change type upon id or customer_id usage
export const deleteCustomerAsync =
  ({
    t,
    id,
    additionalActionAfterSuccess,
  }: {
    t: any;
    id: string | number | undefined;
    additionalActionAfterSuccess: () => void;
  }) =>
  async (dispatch) => {
    dispatch(setIsAddOrEditCustomerRequestLoading(true));
    try {
      const response = await axiosInstance.delete(`customers/${id}`);
      if (response.data) {
        dispatch(deleteCustomerFromCustomersData(id));
        toast.success(t('api_errors.users.delete.success'));
        if (additionalActionAfterSuccess) additionalActionAfterSuccess();
      } else {
        toast.error(t('api_errors.users.delete.fail'));
      }
    } catch (err) {
      toast.error(t('api_errors.users.delete.error'));
    }
    dispatch(setIsAddOrEditCustomerRequestLoading(false));
  };

export const selectIsCustomersDataLoading = (state): boolean =>
  state.adminCustomers.isCustomersDataLoading;
export const selectCustomersData = (state): Array<Customer> => state.adminCustomers.customersData;
export const selectCurrentCustomer = (state): Customer | null =>
  state.adminCustomers.currentCustomer;
export const selectIsAddOrEditCustomerRequestLoading = (state): boolean =>
  state.adminCustomers.isAddOrEditCustomerRequestLoading;
export const selectCustomerDataForEditPanel = (state): Customer | null =>
  state.adminCustomers.customerDataForEditPanel;

export default adminCustomersSlice.reducer;
