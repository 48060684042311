import { ReactElement, useEffect } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import cn from 'classnames';

import SrButton from 'shared/components/SrButton';
import { CLoseIconSVG } from 'shared/svgs';

import styles from './styles.module.scss';

function SrModalContent({
  children,
  title,
  headerClassName,
  contentClassName,
  withCancelButton,
  withAcceptButton,
  withCloseButton,
  cancelBtnTitle,
  acceptBtnTitle,
  onClose,
  onCancel,
  onAccept,
  actionButtonsWrapperAdditionalClassName,
  cancelBtnAdditionalClassName,
  confirmBtnAdditionalClassName,
}): ReactElement {
  const { height: modalWrapperHeight = 0, ref: modalWrapperRef } = useResizeDetector<any>({
    refreshMode: 'debounce',
    refreshRate: 50,
  });

  useEffect(() => {
    if (modalWrapperRef.current) {
      const zoomScaleApplied = document.documentElement.style.getPropertyValue('--zoom-scale');
      modalWrapperRef.current.style.zoom = `${+zoomScaleApplied * 100}%`;
    }
  }, [modalWrapperHeight, modalWrapperRef]);

  return (
    <div
      ref={modalWrapperRef}
      className={cn(styles.SrModalContent__modalContent, contentClassName)}
    >
      <div className={cn(styles.SrModalContent__headerModal, headerClassName)}>
        <div className={styles.SrModalContent__headerTitle}>{title}</div>
        {withCloseButton && (
          <CLoseIconSVG svgClassName={styles.SrModalContent__closeModalBtn} onClick={onClose} />
        )}
      </div>
      <div className={styles.SrModalContent__childrenContent}>{children}</div>

      <div
        className={cn(
          styles.SrModalContent__actionButtonsWrapper,
          actionButtonsWrapperAdditionalClassName
        )}
      >
        {withCancelButton && (
          <SrButton
            title={cancelBtnTitle || 'Cancel'}
            customClassName={cn(
              styles.SrModalContent__actionButtonCancel,
              cancelBtnAdditionalClassName
            )}
            onClick={onCancel || onClose}
          />
        )}
        {withAcceptButton && (
          <SrButton
            title={acceptBtnTitle || 'Confirm'}
            customClassName={cn('', confirmBtnAdditionalClassName)}
            onClick={onAccept}
          />
        )}
      </div>
    </div>
  );
}

export default SrModalContent;
