import { ReactElement } from 'react';

function ShowGpsIconSVG(): ReactElement {
  return (
    <svg width="26" height="20" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0001 11.2C11.1549 11.2 12.1364 10.7917 12.9447 9.975C13.753 9.15833 14.1572 8.16667 14.1572 7C14.1572 5.83333 13.753 4.84167 12.9447 4.025C12.1364 3.20833 11.1549 2.8 10.0001 2.8C8.84539 2.8 7.86386 3.20833 7.05555 4.025C6.24724 4.84167 5.84308 5.83333 5.84308 7C5.84308 8.16667 6.24724 9.15833 7.05555 9.975C7.86386 10.7917 8.84539 11.2 10.0001 11.2ZM10.0001 9.52C9.30728 9.52 8.71837 9.275 8.23338 8.785C7.74839 8.295 7.5059 7.7 7.5059 7C7.5059 6.3 7.74839 5.705 8.23338 5.215C8.71837 4.725 9.30728 4.48 10.0001 4.48C10.693 4.48 11.2819 4.725 11.7669 5.215C12.2519 5.705 12.4943 6.3 12.4943 7C12.4943 7.7 12.2519 8.295 11.7669 8.785C11.2819 9.275 10.693 9.52 10.0001 9.52ZM10.0001 14C7.86001 14 5.90466 13.4283 4.13407 12.285C2.36348 11.1417 1.02399 9.59778 0.115596 7.65333C0.0694061 7.57556 0.0386132 7.47833 0.0232168 7.36167C0.0078203 7.245 0.00012207 7.12444 0.00012207 7C0.00012207 6.87556 0.0078203 6.755 0.0232168 6.63833C0.0386132 6.52167 0.0694061 6.42444 0.115596 6.34667C1.02399 4.40222 2.36348 2.85833 4.13407 1.715C5.90466 0.571667 7.86001 0 10.0001 0C12.1402 0 14.0956 0.571667 15.8662 1.715C17.6368 2.85833 18.9763 4.40222 19.8846 6.34667C19.9308 6.42444 19.9616 6.52167 19.977 6.63833C19.9924 6.755 20.0001 6.87556 20.0001 7C20.0001 7.12444 19.9924 7.245 19.977 7.36167C19.9616 7.47833 19.9308 7.57556 19.8846 7.65333C18.9763 9.59778 17.6368 11.1417 15.8662 12.285C14.0956 13.4283 12.1402 14 10.0001 14ZM10.0001 12.1333C11.7399 12.1333 13.3373 11.6706 14.7923 10.745C16.2472 9.81944 17.3596 8.57111 18.1295 7C17.3596 5.42889 16.2472 4.18056 14.7923 3.255C13.3373 2.32944 11.7399 1.86667 10.0001 1.86667C8.26032 1.86667 6.66294 2.32944 5.20797 3.255C3.75301 4.18056 2.64061 5.42889 1.87079 7C2.64061 8.57111 3.75301 9.81944 5.20797 10.745C6.66294 11.6706 8.26032 12.1333 10.0001 12.1333Z"
        fill="#F2F2F2"
      />
    </svg>
  );
}

export default ShowGpsIconSVG;
