import { ReactElement } from 'react';

function RightArrowIconSVG(): ReactElement {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.398024 13.6002V6.40021H9.99832V0.592285L19.4062 10.0002L9.99832 19.4081L9.99832 13.6002L0.398024 13.6002Z"
        fill="#F2F2F2"
      />
    </svg>
  );
}

export default RightArrowIconSVG;
