// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
HTMLCanvasElement.prototype.getContext = (function (origFn) {
  return function (type, attribs) {
    attribs = attribs || {};
    if (type === 'webgl' || type === 'webgl2') {
      attribs.preserveDrawingBuffer = true;
    }
    return origFn.call(this, type, attribs);
  };
})(HTMLCanvasElement.prototype.getContext);

export {};
