import { ReactElement } from 'react';

function HelpWindowIconSidebarMenuSVG(): ReactElement {
  return (
    <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.2 10.8C5.2 11.0373 5.12962 11.2693 4.99776 11.4667C4.8659 11.664 4.67849 11.8178 4.45922 11.9087C4.23995 11.9995 3.99867 12.0232 3.76589 11.9769C3.53311 11.9306 3.31929 11.8163 3.15147 11.6485C2.98365 11.4807 2.86936 11.2669 2.82306 11.0341C2.77676 10.8013 2.80052 10.5601 2.89134 10.3408C2.98217 10.1215 3.13598 9.93409 3.33332 9.80224C3.53065 9.67038 3.76266 9.6 4 9.6C4.31826 9.6 4.62348 9.72643 4.84853 9.95147C5.07357 10.1765 5.2 10.4817 5.2 10.8ZM4 0C1.794 0 0 1.615 0 3.6V4C0 4.21217 0.0842854 4.41566 0.234314 4.56568C0.384344 4.71571 0.587827 4.8 0.8 4.8C1.01217 4.8 1.21566 4.71571 1.36569 4.56568C1.51571 4.41566 1.6 4.21217 1.6 4V3.6C1.6 2.5 2.677 1.6 4 1.6C5.323 1.6 6.4 2.5 6.4 3.6C6.4 4.7 5.323 5.6 4 5.6C3.78783 5.6 3.58434 5.68428 3.43431 5.83431C3.28429 5.98434 3.2 6.18783 3.2 6.4V7.2C3.2 7.41217 3.28429 7.61566 3.43431 7.76569C3.58434 7.91571 3.78783 8 4 8C4.21217 8 4.41566 7.91571 4.56569 7.76569C4.71571 7.61566 4.8 7.41217 4.8 7.2V7.128C6.624 6.793 8 5.338 8 3.6C8 1.615 6.206 0 4 0Z" fill="#D8D8D8"/>
    </svg>
  );
}

export default HelpWindowIconSidebarMenuSVG;
