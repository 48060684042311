import { ReactElement } from 'react';

function DownArrowIconSVG(): ReactElement {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.39832 0.399H13.5983L13.5983 9.9993H19.4062L9.99833 19.4072L0.590399 9.9993H6.39832V0.399Z"
        fill="#F2F2F2"
      />
    </svg>
  );
}

export default DownArrowIconSVG;
