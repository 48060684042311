import { ReactElement } from 'react';

function CameraIconSVG(): ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 48 48">
      <g transform="translate(0.000000,48.000000) scale(0.100000,-0.100000)" fill="#F9F9F9">
        <path d="M168 400 c-18 -25 -29 -30 -64 -30 -68 0 -74 -13 -74 -150 0 -94 3 -122 16 -134 23 -24 365 -24 388 0 13 12 16 40 16 134 0 137 -6 150 -74 150 -35 0 -46 5 -64 30 -20 26 -29 30 -72 30 -43 0 -52 -4 -72 -30z m130 -10 c12 -19 10 -20 -43 -20 -30 0 -55 -4 -55 -10 0 -5 49 -11 113 -12 l112 -3 0 -125 0 -125 -185 0 -185 0 0 125 0 125 52 3 c46 3 55 7 70 33 15 25 23 29 63 29 34 0 48 -5 58 -20z" />
        <path d="M179 281 c-20 -20 -29 -39 -29 -61 0 -43 47 -90 90 -90 43 0 90 47 90 90 0 43 -47 90 -90 90 -22 0 -41 -9 -61 -29z m108 -13 c29 -27 29 -65 1 -95 -27 -29 -65 -29 -95 -1 -29 27 -29 65 -1 95 27 29 65 29 95 1z" />
      </g>
    </svg>
  );
}

export default CameraIconSVG;
