import { ReactElement } from 'react';

import styles from './styles.module.scss';

function ProgressByPulsecountBar({ completeRunProgressByPulsecount }): ReactElement {
  return (
    <div className={styles.ProgressByPulsecountBar__wrapper}>
      <div
        style={{
          position: 'absolute',
          height: '100%',
          width: `${completeRunProgressByPulsecount}%`,
          top: '0',
          left: '0',
          borderRadius: '2px',
          backgroundColor: '#FFDE99',
        }}
      />
    </div>
  );
}

export default ProgressByPulsecountBar;
