import {
  REACT_APP_AWS_OAUTH_DOMAIN,
  REACT_APP_AWS_OAUTH_REDIRECT_SIGNIN_SIGNOUT,
  REACT_APP_AWS_REGION,
  REACT_APP_AWS_USER_POOL_ID,
  REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
} from 'shared/envs';

const awsConfig = {
  Auth: {
    region: REACT_APP_AWS_REGION,
    userPoolId: REACT_APP_AWS_USER_POOL_ID,
    userPoolWebClientId: REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
  },
  oauth: {
    domain: REACT_APP_AWS_OAUTH_DOMAIN,
    scope: ['email', 'openid'],
    redirectSignIn: REACT_APP_AWS_OAUTH_REDIRECT_SIGNIN_SIGNOUT,
    redirectSignOut: REACT_APP_AWS_OAUTH_REDIRECT_SIGNIN_SIGNOUT,
    responseType: 'code',
  },
};

export default awsConfig;
