import { ReactElement } from 'react';

function UnlockIconSVG(): ReactElement {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M 21.5625 0 C 17.945312 0 15 2.945312 15 6.5625 L 15 13.125 L 3.75 13.125 C 2.714844 13.125 1.875 13.964844 1.875 15 L 1.875 28.125 C 1.875 29.160156 2.714844 30 3.75 30 L 20.625 30 C 21.660156 30 22.5 29.160156 22.5 28.125 L 22.5 15 C 22.5 13.964844 21.660156 13.125 20.625 13.125 L 18.75 13.125 L 18.75 6.5625 C 18.75 5.011719 20.011719 3.75 21.5625 3.75 C 23.113281 3.75 24.375 5.011719 24.375 6.5625 L 24.375 11.25 C 24.375 12.285156 25.214844 13.125 26.25 13.125 C 27.285156 13.125 28.125 12.285156 28.125 11.25 L 28.125 6.5625 C 28.125 2.945312 25.179688 0 21.5625 0 Z M 13.125 23.265625 L 13.125 25.3125 C 13.125 25.832031 12.707031 26.25 12.1875 26.25 C 11.667969 26.25 11.25 25.832031 11.25 25.3125 L 11.25 23.265625 C 10.160156 22.878906 9.375 21.847656 9.375 20.625 C 9.375 19.070312 10.632812 17.8125 12.1875 17.8125 C 13.742188 17.8125 15 19.070312 15 20.625 C 15 21.847656 14.214844 22.878906 13.125 23.265625 Z M 13.125 23.265625"
        fill="#ffd200"
      />
    </svg>
  );
}

export default UnlockIconSVG;
