export interface TestedLocationsGenaralInfo {
  miles_data_collection: number;
  minutes_data_collection: number;
  average_data_collection_speed: string;
}

export interface TestedTrackLocations {
  railroad: string;
  division: string;
  subdivision: string;
  track_id: string;
  start_post: number;
  end_post: number;
  start_pulse: number;
  end_pulse: number;
}

export interface TestedLocations {
  general_info: TestedLocationsGenaralInfo;
  track_locations: Array<TestedTrackLocations>;
}

export interface SuspectsCount {
  verification_locations: number | undefined;
  priority_one_suspects: number | undefined;
  priority_two_suspects: number | undefined;
  priority_three_suspects: number | undefined;
}

export interface Technologies {
  ultrasonics: boolean | undefined;
  x_fire: boolean | undefined;
  induction: boolean | undefined;
  vision: boolean | undefined;
}

export interface AnalystRecognitions {
  recognition_id: string;
  run_id: string;
  test_unit: string;
  contract_area: string;
  engineer_line_reference: string;
  track_id: string;
  rail: string;
  mile_post: string;
  pulse_count: number;
  priority: number;
  defect_code: string;
  analyst_comment: string;
  gps_location: {
    latitude: number;
    longitude: number;
  };
  walkout_yards: number;
  suspect_group: string;
}

export interface AnalystRecognitionsAllTypes {
  new_suspect_rail_defects_for_verification: Array<AnalystRecognitions>;
  repeat_marked_objects_for_verification: Array<AnalystRecognitions>;
  repeat_marked_objects_not_for_verification: Array<AnalystRecognitions>;
}

export interface SummaryReportData {
  run_id: string;
  tested_locations: TestedLocations;
  suspects_count: SuspectsCount;
  technologies: Technologies;
  analyst_recognitions: AnalystRecognitionsAllTypes;
}

export const DEFAULT_SUMMARY_REPORT_DATA: SummaryReportData = {
  run_id: '',
  tested_locations: {
    general_info: {
      miles_data_collection: 0,
      minutes_data_collection: 0,
      average_data_collection_speed: '',
    },
    track_locations: [],
  },
  suspects_count: {
    verification_locations: undefined,
    priority_one_suspects: undefined,
    priority_two_suspects: undefined,
    priority_three_suspects: undefined,
  },
  technologies: {
    ultrasonics: undefined,
    x_fire: undefined,
    induction: undefined,
    vision: undefined,
  },
  analyst_recognitions: {
    new_suspect_rail_defects_for_verification: [],
    repeat_marked_objects_for_verification: [],
    repeat_marked_objects_not_for_verification: [],
  },
};

// consts for mapping fields names
export enum analystRecognitionsCategoriesList {
  new_suspect_rail_defects_for_verification = 'New suspect rail defects requiring verification',
  repeat_marked_objects_for_verification = 'Repeat marked objects requiring verification',
  repeat_marked_objects_not_for_verification = 'Repeat marked objects that do not require verification',
}

export enum testedLocationsGeneralInfoList {
  miles_data_collection = 'Miles of Data Collection',
  minutes_data_collection = 'Minutes of Data Collection',
  average_data_collection_speed = 'Average Data Collection Speed',
}

export enum suspectsCountsList {
  verification_locations = 'Verification Locations (see attached report)',
  priority_one_suspects = 'Priority one suspects',
  priority_two_suspects = 'Priority two suspects',
  priority_three_suspects = 'Priority three suspects',
}

export enum technologiesList {
  ultrasonics = 'Ultrasonics',
  x_fire = 'X-Fire ©',
  induction = 'Induction',
  vision = 'Vision',
}

export const SUMMARY_REPORT_HEADER_HEIGHT = 56;
export const MARGINS_TOP_BOTTOM_FOR_SUMMARY_REPORT_TAB = 35;
export const MARGINS_BETWEEN_BLOCKS_SUMMARY_REPORT_TAB = 35;
export const TITLES_HEIGHT_WITH_MARGINS = 84;
export const ANALYST_MARKED_OBJECTS_TABLE_HEADER_HEIGHT = 65;
export const SELECTED_TL_EMPTY_TABLE_PLACEHOLDER_HEIGHT = 50;
