// TODO: check real types and what we need in FE
import moment from 'moment';

export interface Task {
  id: number | string;
  company_id: number | string;
  company: string;
  vehicle: string;
  recording: string;
  audit_flag: boolean;
  status: RealRunStatuses;
  sandv: boolean;
  nonstop: boolean;
  analyst: string | number;
  analyst_progress: number;
  analyst_suspects: number;
  auditor: string | number;
  auditor_progress: number;
  auditor_suspects: number;
  info: string;
  preprocessing: string;
  preprocessing_progress: number;
  mode: RunModes;
  created_date: string;
  last_modification_date: string;
  last_modification_user: string;
  email_sent: boolean;
  // quality_status: QualityStatuses;
}

export enum RunModes {
  SANDV = 'S&V',
  NONSTOP = 'Nonstop',
}

export enum dateRangeValues {
  EMPTY = 'Select date range',
  HOURS_48 = '48 hours',
  LAST_7_DAYS = 'Last 7 days',
  LAST_30_DAYS = 'Last 30 days',
  CUSTOM_RANGE = 'Custom range',
  ALL_TIME = 'All time',
}

export enum userProcessingValues {
  ALL = 'All',
  NOT_ANALYZED = 'Not analyzed',
  NOT_AUDITED = 'Not audited',
}

export const formatSelectedDataToNesassaryFormat = (data): any => {
  const formattingData = moment(data).format('DD/MM/YYYY');
  return formattingData;
};

export enum RealRunStatuses {
  ALL = 'all',
  PROCESSING = 'elmer_processing',
  AVAILABLE = 'available',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
}

export enum RunStatuses {
  ALL = 'All',
  NOT_AVAILABLE_FOR_ANALYST = 'Elmer processing',
  AVAILABLE_FOR_ASSIGNMENT = 'Available for assignment',
  NOT_AVAILABLE_FOR_AUDIT = 'Not available for audit',
  AVAILABLE_FOR_AUDIT = 'Available for audit',
  PROCESSING = 'Elmer processing',
  IN_PROGRESS = 'In progress',
  COMPLETED = 'Completed',
  AUDIT_COMPLETED = 'Audit completed',
}

export const RUNS_LIMIT_FOR_PAGINATION = 20;
export const DEFAULT_RUNS_TABLE_HEIGHT = 500;

export const HEADER_BLOCK_HEIGHT = 56;
export const MARGIN_BOTTOM_FOR_RUNS_TABLE = 30;
export const ADMIN_SUPERVISOR_TASKS_TITLE_AND_ASSIGNMENTS_BTNS = 105;
