import { ReactElement } from 'react';

function LeftArrowIconSVG(): ReactElement {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.602 6.39979V13.5998H10.0017V19.4077L0.59375 9.99979L10.0017 0.591864L10.0017 6.39979L19.602 6.39979Z"
        fill="#F2F2F2"
      />
    </svg>
  );
}

export default LeftArrowIconSVG;
