import { ReactElement } from 'react';

function GoogleMapToHideArrowBtnSVG(): ReactElement {
  return (
    <svg width="15" height="8" viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.5 1L7.5 7L1.5 0.999999"
        stroke="#949494"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default GoogleMapToHideArrowBtnSVG;
